import React from 'react';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';
import ChatBar from 'layouts/ChatBar/ChatBar';
import StartModeling from "../StartModeling";


const ModelingSetupTab = ({ initialState }) => {

  return (
        <MDBox p={3}>
            <MDTypography variant="h3" >
                Modeling Setup
            </MDTypography>
{/*             <ChatBar /> */}
            <br />
            <Card>
            <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
            <MDTypography variant="body2" >
                <StartModeling initialState={initialState}/>
            </MDTypography>
           </MDBox>
          </Card>
        <br />
        </MDBox>
    );
};

export default ModelingSetupTab;