import React, { useState, useEffect, useCallback, useContext } from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import ChatBar from 'layouts/ChatBar/ChatBar';
import MarketingSummaryTable from './MarketingSummaryTable';
import MarketingGraph from './MarketingGraph';
import GetFileData from './../GetFileData';
import CircularProgress from '@mui/material/CircularProgress';
import { blue } from '@mui/material/colors';
import { useBrand } from '../../../../BrandContext';

const MarketingTab = ({ selectedFile = null }) => {
  // Initialize state with data from localStorage if available
  const [data, setData] = useState(() => {
    const savedData = localStorage.getItem('marketingData');
    return savedData ? JSON.parse(savedData) : null;
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Update localStorage when data changes
  useEffect(() => {
    if (data) {
      localStorage.setItem('marketingData', JSON.stringify(data));
    }
  }, [data]);

  const onGetData = useCallback((sheetName, data) => {
    setData(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (selectedFile) {
      setLoading(true);
      setData(null);
    }
  }, [selectedFile]);

  // Effect to handle errors and DOM checks
  useEffect(() => {
     if (error) {
        console.error('Error:', error);
     }
  }, [error]);

  return (
    <>
      <MDBox >
        <MDTypography variant="h3">
          Marketing
        </MDTypography>
        <MDTypography variant="body2" mt={2} mb={5} fontSize="0.9rem">
          File selected: <b>{selectedFile?.file?.file_name || "No file selected"}</b>
        </MDTypography>
        {selectedFile && (
          <GetFileData
            sheetName="Advertising"
            onGetData={onGetData}
            file={selectedFile}
          />
        )}
        {loading ? (
          <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress sx={{ color: blue[500] }} />
             <MDTypography variant="h6" sx={{ mt: 2 }}>
                 Loading the Marketing graph for {selectedFile ? selectedFile.file.file_name : 'the selected file'}...
            </MDTypography>
          </MDBox>
        ) : (
            selectedFile && data && (
              <>
                <MarketingSummaryTable data={data} />
                <br />
{/*                 <MarketingGraph data={data} /> */}
              </>
              )
        )}
      </MDBox>
    </>
  );
};

export default MarketingTab;
