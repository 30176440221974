import React from 'react';
import { Grid, Typography, TextField, MenuItem } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

function NameAndSettings({
    scenarioName,
    scenarioDescription,
    scenarioModelType,
//     setScenarioName,
//     setScenarioDescription,
//     handleObjectiveChange,
//     handleOptimizationGoalChange,
    scenarioObjective,
    optimizationGoal,
    refreshName,
    budgetTimingStart,
    budgetTimingEnd,
    setBudgetTimingStart,
    setBudgetTimingEnd,
    roi
}) {
    const textFieldStyles = {
        margin: 'dense',
        variant: 'outlined',
        fullWidth: true,
        sx: {
            '& .MuiInputBase-root': {
                height: '44px'
            },
        },
    };

    return (
        <Grid container spacing={3}>

            <Grid item xs={12} sm={6}>
                <Typography variant="h6">Scenario Name</Typography>
                <TextField
                    {...textFieldStyles}
                    value={scenarioName}
                    disabled
//                     onChange={(e) => setScenarioName(e.target.value)}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <Typography variant="h6">Description</Typography>
                <TextField
                    {...textFieldStyles}
                    value={scenarioDescription}
                    disabled
//                     onChange={(e) => setScenarioDescription(e.target.value)}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <Typography variant="h6">Scenario Objective</Typography>
                <TextField
                    {...textFieldStyles}
                    select
                    value={scenarioObjective}
                    disabled
//                     onChange={handleObjectiveChange}
                >
                    <MenuItem value="Optimize a Fixed Budget">Optimize a Fixed Budget</MenuItem>
                    <MenuItem value="Recommend a New Budget">Recommend a New Budget</MenuItem>
                    <MenuItem value="Meet Revenue Goal">Meet Revenue Goal</MenuItem>
                </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Typography variant="h6">Optimization Goal</Typography>
                <TextField
                    {...textFieldStyles}
                    select
                    value={optimizationGoal}
                    disabled
//                     onChange={handleOptimizationGoalChange}
                >
                    <MenuItem value="Revenue">Revenue</MenuItem>
                    <MenuItem value="ROI">ROI</MenuItem>
                    <MenuItem value="Profits">Profits</MenuItem>
                </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
                <Typography variant="h6" sx={{ mb: 2 }}>Budget Timing</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Start Date"
                        value={budgetTimingStart}
                        onChange={(newValue) => setBudgetTimingStart(newValue)}
                        renderInput={(params) => <TextField {...params} {...textFieldStyles} />}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={2}>
                <Typography variant="h6" sx={{ visibility: 'hidden', mb: 2 }}>End Date</Typography> {/* Invisible text for alignment */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="End Date"
                        value={budgetTimingEnd}
                        onChange={(newValue) => setBudgetTimingEnd(newValue)}
                        renderInput={(params) => <TextField {...params} {...textFieldStyles} />}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={2}>
                <Typography variant="h6" sx={{ mb: 1 }}>ROI</Typography>
                <TextField
                    {...textFieldStyles}
                    value={roi}
                    disabled
                />
            </Grid>

            <Grid item xs={12} sm={3}>
                <Typography variant="h6" sx={{ mb: 1 }}>Refresh</Typography>
                    <TextField
                        {...textFieldStyles}
                        select
                        value={refreshName}
                        disabled
                    >
                       <MenuItem value={refreshName}>{refreshName || "No Refresh Selected"}</MenuItem>
                    </TextField>
            </Grid>

             <Grid item xs={12} sm={3}>
                <Typography variant="h6" sx={{ mb: 1 }}>Model Type</Typography>
                    <TextField
                        {...textFieldStyles}
                        select
                        value={scenarioModelType}
                        disabled
                    >
                       <MenuItem value={scenarioModelType}>{scenarioModelType || "No Model Type Selected"}</MenuItem>
                    </TextField>
            </Grid>
        </Grid>
    );
}

export default NameAndSettings;
