import React from 'react';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import 'assets/css/table.css';

const DataLogTab = () => {
    return (
      <>
         <>
        <MDBox>
            <MDTypography variant="h3" >
                Data Log
            </MDTypography>
        </MDBox>
        <br/>
      <Card>
          <MDBox pt={3} px={2} height="300px">
            <MDTypography variant="normal" fontWeight="normal">
                Coming soon...
            </MDTypography>
          </MDBox>
       </Card>
      <br />
    </>
      </>
    );
};

export default DataLogTab;
