import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, IconButton } from '@mui/material';
import { Range, getTrackBackground } from 'react-range';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import MDButton from "components/MDButton";
import { NumericFormat } from 'react-number-format';
import DataTable from "assets/Tables/DataTable";

const STEP = 1;
const MIN = -100;
const MAX = 100;

function Constraints({ constraints, setConstraints, totalBudget, setTotalBudget, budgetTimingStart, budgetTimingEnd, modelResults }) {

    const [featureNames, setFeatureNames] = useState([]);

    useEffect(() => {
        if (budgetTimingStart && budgetTimingEnd && modelResults) {
            const featureCoefficients = modelResults.revenue.feature_coefficients;
            const names = Object.keys(featureCoefficients);
            const xInputIntervalNonTransformed = modelResults.revenue.result.x_input_interval_nontransformed;

            // Get start and end times in milliseconds
            const startDate = new Date(budgetTimingStart).getTime();
            const endDate = new Date(budgetTimingEnd).getTime();

            // Filter data by date range
            const filteredData = xInputIntervalNonTransformed.filter(entry => {
                const entryDate = new Date(entry.date).getTime();
                return entryDate >= startDate && entryDate <= endDate;
            });

            // Calculate initial spends for each feature
            const initialSpends = names.reduce((acc, name) => {
                acc[name] = filteredData.reduce((sum, entry) => {
                    return sum + (entry[name] || 0);
                }, 0);
                return acc;
            }, {});

            // Set feature names
            setFeatureNames(names);

            // Create new constraints based on the initial spends
            const newConstraints = names.map(name => ({
                id: name,
                name: name,
                latestSpend: initialSpends[name] || 0,
                minAmount: initialSpends[name] * 0.7,
                maxAmount: initialSpends[name] * 1.3,
                percentLimit: [-30, 30], // Default percentage limits
            }));

            // Update the state with the new constraints
            setConstraints(newConstraints);

            // Calculate and set the total budget
            const calculatedTotalBudget = Object.values(initialSpends).reduce((sum, spend) => sum + spend, 0);
            setTotalBudget(calculatedTotalBudget);
        }
    }, [budgetTimingStart, budgetTimingEnd, modelResults]);

//     const handleTotalBudgetChange = (event) => {
//         setTotalBudget(event.target.value);
//     };

    const handleAddConstraint = () => {
        setConstraints(prevConstraints => [
            ...prevConstraints,
            {
                id: prevConstraints.length + 1,
                name: '',
                latestSpend: 0,
                minAmount: 0,
                maxAmount: 0,
                percentLimit: [-30, 30], // Default values for the double slider
            }
        ]);
    };

    const handleDeleteConstraint = (index) => {
        setConstraints(prevConstraints => prevConstraints.filter((_, i) => i !== index));
    };

    const handleConstraintChange = (index, field) => (event) => {
        const updatedConstraints = [...constraints];
        updatedConstraints[index][field] = event.target.value;
        // Update Min and Max values when Initial Spend changes
        if (field === 'latestSpend') {
            const initialSpend = parseFloat(event.target.value) || 0;
            const percentLimit = updatedConstraints[index].percentLimit;
            updatedConstraints[index].minAmount = initialSpend * (1 + percentLimit[0] / 100);
            updatedConstraints[index].maxAmount = initialSpend * (1 + percentLimit[1] / 100);
        }
        setConstraints(updatedConstraints);
    };

    const handleSliderChange = (index, values) => {
        const updatedConstraints = [...constraints];
        updatedConstraints[index].percentLimit = values;
        const initialSpend = parseFloat(updatedConstraints[index].latestSpend) || 0;
        updatedConstraints[index].minAmount = initialSpend * (1 + values[0] / 100);
        updatedConstraints[index].maxAmount = initialSpend * (1 + values[1] / 100);
        setConstraints(updatedConstraints);
    };

    // DataTable columns configuration with updated headers
    const columns = [
        { Header: <Typography fontWeight="bold" variant="h6">Action</Typography>, accessor: "action" },
        { Header: <Typography fontWeight="bold" variant="h6">Name</Typography>, accessor: "name"},
        { Header: <Typography fontWeight="bold" variant="h6">Initial Spend</Typography>, accessor: "latestSpend"},
        { Header: <Typography fontWeight="bold" variant="h6">Min $</Typography>,  accessor: "minAmount" },
        {  Header: <Typography fontWeight="bold" variant="h6">Max $</Typography>, accessor: "maxAmount" },
        { Header: <Typography fontWeight="bold" variant="h6">Percent Limits</Typography>, accessor: "percentLimit" }
    ];

    // Prepare the rows for DataTable
    const rows = constraints.map((constraint, index) => ({
        action: (
            <IconButton color="error" onClick={() => handleDeleteConstraint(index)}>
                <RemoveIcon />
            </IconButton>
        ),
        name: (
            <TextField
                variant="outlined"
                value={constraint.name}
                onChange={handleConstraintChange(index, 'name')}
                fullWidth
                sx={{ width: '120px' }}
            />
        ),
        latestSpend: (
            <NumericFormat
              value={constraint.latestSpend}
              thousandSeparator=","
              prefix="$"
              decimalScale={2}
              fixedDecimalScale={true}
              customInput={TextField}
              variant="outlined"
              sx={{ width: '130px', color: '#000' }}
              disabled
            />
        ),
        minAmount: (
            <NumericFormat
                value={constraint.minAmount.toFixed(2)}
                thousandSeparator
                prefix="$"
                decimalScale={2}
                fixedDecimalScale={true}
                customInput={TextField}
                variant="outlined"
                sx={{ width: '130px', color: '#000' }}
                disabled
            />
        ),
        maxAmount: (
            <NumericFormat
                value={constraint.maxAmount.toFixed(2)}
                thousandSeparator
                prefix="$"
                decimalScale={2}
                fixedDecimalScale={true}
                customInput={TextField}
                variant="outlined"
                sx={{ width: '130px', color: '#000' }}
                disabled
            />
        ),
        percentLimit: (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '250px' }}>
                <Range
                    values={constraint.percentLimit}
                    step={STEP}
                    min={MIN}
                    max={MAX}
                    onChange={(values) => handleSliderChange(index, values)}
                    renderTrack={({ props, children }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '6px',
                                width: '100%', // Ensure full width
                                background: getTrackBackground({
                                    values: constraint.percentLimit,
                                    colors: ['#ccc', '#548BF4', '#ccc'], // Adjusted to show track color
                                    min: MIN,
                                    max: MAX,
                                }),
                                borderRadius: '4px', // Rounded corners for the track
                                marginTop: '10px', // Space above slider
                                marginBottom: '10px', // Space below slider
                            }}
                        >
                            {children}
                        </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '20px',
                                width: '20px',
                                borderRadius: '50%',
                                backgroundColor: isDragged ? '#548BF4' : '#FFF', // Clearer thumb color
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: '0px 2px 6px #AAA',
                                outline: 'none',
                            }}
                        >
                            <div
                                style={{
                                    height: '10px',
                                    width: '2px',
                                    backgroundColor: isDragged ? '#FFF' : '#548BF4', // Thumb indicator color
                                }}
                            />
                        </div>
                    )}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <span style={{ fontSize: '12px', color: '#333' }}>-100%</span>
                    <span style={{ fontSize: '12px', color: '#333' }}>-30%</span>
                    <span style={{ fontSize: '12px', color: '#333' }}>0%</span>
                    <span style={{ fontSize: '12px', color: '#333' }}>+30%</span>
                    <span style={{ fontSize: '12px', color: '#333' }}>+100%</span>
                </div>
            </div>
        ),
    }));

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}>
                <Typography variant="h6" sx={{ marginRight: 2 }}>Total Budget Limit:</Typography>
                 <NumericFormat
                  value={totalBudget}
//                   onChange={handleTotalBudgetChange}
                  thousandSeparator=","
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale={true}
                  customInput={TextField}
                  variant="outlined"
                  sx={{ width: '150px', color: '#000' }}
                  disabled
                />
            </Box>

            {/* Channel Limits Section */}
            <Typography variant="h6">Channel Limits</Typography>
             <div className="data-table-wrapper">
                <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                />
             </div>

            {/* Add Limit Button at the Bottom */}
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                <MDButton
                    variant="contained"
                    color="dark"
                    startIcon={<AddIcon />}
                    onClick={handleAddConstraint}
                    sx={{ marginTop: 2 }}
                >
                    Add Constraint
               </MDButton>
            </Box>
        </Box>
    );
}

export default Constraints;
