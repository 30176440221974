import React, { useContext, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useBrand } from '../../../../BrandContext';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 205,
    },
  },
};

function getStyles(name, date, theme) {
  return {
    fontWeight:
      date.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function DateVariable({ dateColumns = [] }) {
  const theme = useTheme();
  const { configurationDataUserSelection, updateConfigurationDataUserSelection } = useBrand();
  if (!Array.isArray(dateColumns)) {
    dateColumns = [];
  }

  const filterValidVariables = (variables) => Array.isArray(variables) ? variables.filter(variable => dateColumns.includes(variable)) : [];

  const [date, setDate] = React.useState(() => {
    if (configurationDataUserSelection?.date && Array.isArray(configurationDataUserSelection.date) && configurationDataUserSelection.date.length) {
      return filterValidVariables(configurationDataUserSelection.date);
    }
    return dateColumns.length > 0 ? [dateColumns[0]] : [];
  });

  useEffect(() => {
    const initialDate = configurationDataUserSelection?.date && Array.isArray(configurationDataUserSelection.date) && configurationDataUserSelection.date.length ? filterValidVariables(configurationDataUserSelection.date) : (dateColumns.length > 0 ? [dateColumns[0]] : []);
    setDate(initialDate);
    updateConfigurationDataUserSelection('date', initialDate);
  }, [dateColumns, configurationDataUserSelection?.date, updateConfigurationDataUserSelection]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const newValue = typeof value === 'string' ? value.split(',') : value;
    const validValues = filterValidVariables(newValue);
    setDate(validValues);
    updateConfigurationDataUserSelection('date', validValues);
  };

  return (
    <FormControl sx={{ m: 1, width: 210 }}>
      <InputLabel id="date-variable-label">Date</InputLabel>
      <Select
        sx={{ margin: '8px', padding: '5px 0' }}
        labelId="date-variable-label"
        id="multiple-date-variable"
        value={date.length > 0 ? date : ''}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-date-variable" label="Date" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} sx={{ height: '10px', padding: '8px 1px' }} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
      {dateColumns.length > 0 ? (
          dateColumns.map((name) => (
            <MenuItem
              sx={{ height: '15px', padding: '10px', marginTop: '5px' }}
              key={name}
              value={name}
              style={getStyles(name, date, theme)}
            >
              {name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No options available</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
