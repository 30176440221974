import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useBrand } from '../../../../../BrandContext';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 205,
    },
  },
};

function getStyles(name, adsModeledMetric, theme) {
  return {
    fontWeight:
      adsModeledMetric.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AdsModeledMetric({ ads_modeled_metric = [] }) {
  const theme = useTheme();
  const { configurationDataUserSelection, updateConfigurationDataUserSelection } = useBrand();

  if (!Array.isArray(ads_modeled_metric)) {
    ads_modeled_metric = [];
  }

  const filterValidMetrics = (metrics) => Array.isArray(metrics) ? metrics.filter(metric => ads_modeled_metric.includes(metric)) : [];

  const [adsModeledMetric, setAdsModeledMetric] = useState(() => {
    const initialMetrics = configurationDataUserSelection?.adsModeledMetric && configurationDataUserSelection.adsModeledMetric.length > 0 ? configurationDataUserSelection.adsModeledMetric : [];
    return filterValidMetrics(initialMetrics.length ? initialMetrics : ads_modeled_metric.length ? [ads_modeled_metric[0]] : []);
  });

  useEffect(() => {
    const initialMetrics = configurationDataUserSelection?.adsModeledMetric && configurationDataUserSelection.adsModeledMetric.length > 0 ? configurationDataUserSelection.adsModeledMetric : ads_modeled_metric.length ? [ads_modeled_metric[0]] : [];
    const validInitialMetrics = filterValidMetrics(initialMetrics);
    setAdsModeledMetric(validInitialMetrics);
    updateConfigurationDataUserSelection('adsModeledMetric', validInitialMetrics);
  }, [ads_modeled_metric, configurationDataUserSelection?.adsModeledMetric, updateConfigurationDataUserSelection]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const newValue = Array.isArray(value) ? value : value.split(',');
    const validValues = filterValidMetrics(newValue);

    setAdsModeledMetric(validValues);
    updateConfigurationDataUserSelection('adsModeledMetric', validValues);
  };

  return (
    <FormControl sx={{ m: 1, width: 205 }}>
      <InputLabel id="modeled-metric-label">Modeled Metric</InputLabel>
      <Select
        sx={{ margin: '8px', padding: '5px 0' }}
        labelId="modeled-metric-label"
        id="multiple-modeled-metric"
        value={adsModeledMetric.length > 0 ? adsModeledMetric : []}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-modeled-metric" label="Modeled Metric" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} sx={{ height: '10px', padding: '8px 1px' }} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {ads_modeled_metric.length > 0 ? (
          ads_modeled_metric.map((name) => (
            <MenuItem
              sx={{ height: '15px', padding: '10px', marginTop: '5px' }}
              key={name}
              value={name}
              style={getStyles(name, adsModeledMetric, theme)}
            >
              {name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No options available</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
