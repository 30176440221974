import { useState, useCallback, useMemo } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "assets/Footer";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { Breadcrumbs, Link, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ModelsResultsTab from 'layouts/ai-insights/components/ModelsResultsTab';
import ROISummaryTab from 'layouts/ai-insights/components/ROISummaryTab';
import { useBrand } from '../../BrandContext';

function AIInsights() {
  const { selectedBrand, selectedRefresh } = useBrand();
  const [tabValue, setTabValue] = useState(0);
  const { modelType } = useParams();
  const navigate = useNavigate();

  const handleSetTabValue = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const tabStyle = useCallback((index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  }), [tabValue]);

  const breadcrumb = useMemo(() => [
    ...(selectedBrand ? [{ label: `Brand: ${selectedBrand.brand_name}`, link: `/brand-manager/${selectedBrand.brand_name}` }] : [{ label: `Please select a brand first!` }]),
    ...(selectedRefresh ? [{ label: `Refresh: ${selectedRefresh.refresh_name}`, link: `/brand-manager/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}` }] : []),
    ...(selectedRefresh?.selectedFile ? [{ label: `File selected: ${selectedRefresh.selectedFile.file.file_name}`, link: `/brand-manager/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}` }] : [])
  ], [selectedBrand, selectedRefresh]);

  const renderBreadcrumbs = useCallback(() => (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {breadcrumb.map((item, index) => (
        <Typography key={index} color="inherit" variant="normal">
          {item.link ? (
            <Link
              color="inherit"
              onClick={() => navigate(item.link)}
              sx={{ fontWeight: 'bold', cursor: 'pointer' }}
            >
              {item.label}
            </Link>
          ) : (
            <span sx={{ fontWeight: 'bold' }}>
              {item.label}
            </span>
          )}
        </Typography>
      ))}
    </Breadcrumbs>
  ), [breadcrumb, navigate]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AppBar position="static">
        <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
          <Tab
            label="Model Results"
            icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>checklist_rtl</Icon>}
            style={tabStyle(0)}
          />
          <Tab
            label="ROI Summary"
            icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>dataset</Icon>}
            style={tabStyle(1)}
          />
        </Tabs>
        <MDBox sx={{ p: 2, pl: 1, mt: 1 }}>
          {renderBreadcrumbs()}
        </MDBox>
      </AppBar>
      {tabValue === 0 && <ModelsResultsTab refresh={selectedRefresh} modelType={modelType} />}
      {tabValue === 1 && <ROISummaryTab refresh={selectedRefresh} modelType={modelType} />}
      <Footer />
    </DashboardLayout>
  );
}

export default AIInsights;
