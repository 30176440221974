import React, { useState, useEffect } from 'react';
import { useAuthInfo } from "@propelauth/react";
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const AddRefreshDialog = ({ open, onClose, onSuccess, selectedBrand }) => {
  const [refresh_name, setRefreshName] = useState('');
  const [files, setFiles] = useState([]);
  const [alert, setAlert] = useState({ show: false, message: '', color: '' });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [refreshNameError, setRefreshNameError] = useState('');
  const auth = useAuthInfo();

  const sanitizeName = (name) => {
      // Replace any character that is not a letter, number, space, or dash with an empty string
      return name.replace(/[^a-zA-Z0-9\s-]/g, '');
  };

  const handleSubmit = async () => {
    let hasError = false;

    if (!refresh_name) {
      setRefreshNameError('Refresh Name is required');
      hasError = true;
    } else {
      setRefreshNameError('');
    }

    if (files.length === 0) {
      setAlert({ show: true, message: 'At least one file is required', color: 'error' });
      hasError = true;
    } else {
      setAlert({ show: false, message: '', color: '' });
    }

    if (hasError) {
      return;
    }

    if (hasError) {
      return;
    }

    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('brand_name', selectedBrand.brand_name);
    formData.append('refresh_name', refresh_name);

    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const fullUrl = `${baseUrl}/brands/create_refresh`;

    try {
      const response = await axios.post(fullUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${auth.accessToken}`
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
        }
      });

      if (response.data) {
        cleanupLocalData();
        setAlert({ show: true, message: response.data.message, color: 'success' });
        onSuccess(response.data);
        onClose();
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setAlert({ show: true, message: `${error.response.data.detail}`, color: 'error' });
    }
  };

  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  const removeFiles = () => {
    setFiles([]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    multiple: true, // Allow multiple file selection
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls']
    },
    onDropRejected: () => {
      setAlert({ show: true, message: 'Only Excel or CSV files are allowed!', color: 'warning' });
    }
  });

  const cleanupLocalData = () => {
    setRefreshName('');
    setFiles([]);
    setAlert({ show: false, message: '', color: '' });
    setRefreshNameError('');
  };

  useEffect(() => {
    if (!open) {
      cleanupLocalData();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
              onClose();
            }
          }}
          onKeyPress={(e) => { if (e.key === 'Enter') handleSubmit(); }}
          disableEscapeKeyDown
      >
      <DialogTitle>Create a Refresh</DialogTitle>
      <DialogContent sx={{ mt: -2 }}>
        {alert.show && <Alert severity={alert.color}>{alert.message}</Alert>}
      </DialogContent>
      <DialogContent>
        <TextField
          margin="dense"
          label="Refresh Name"
          fullWidth
          variant="outlined"
          value={sanitizeName(refresh_name)}
          onChange={e => setRefreshName(e.target.value)}
          error={!!refreshNameError}
          helperText={refreshNameError}
        />
        <MDBox sx={{ mt: 1 }}>
          <MDTypography variant="body2">Files Upload</MDTypography>
          <MDBox {...getRootProps()} sx={{ border: '2px dashed #ccc', borderRadius: '4px', p: 2, textAlign: 'center', mt: 1, position: 'relative', cursor: 'pointer' }}>
            <input {...getInputProps()} />
            {files.length === 0 ? (
              <MDTypography variant="h6">Drop your file(s) here or click</MDTypography>
            ) : (
              <MDBox>
                {files.map((file, index) => (
                  <MDTypography variant="h6" key={index}>{file.name}</MDTypography>
                ))}
              </MDBox>
            )}
            {files.length > 0 && (
              <IconButton
                size="small"
                onClick={removeFiles}
                sx={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{'&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.08)',}}} >
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          sx={{
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddRefreshDialog;
