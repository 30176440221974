import React, { useState, useEffect } from 'react';
import { Grid, Box, Paper, Typography, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Button } from '@mui/material';
import Plot from 'react-plotly.js';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import DownloadIcon from '@mui/icons-material/Download';
import DataTable from "assets/Tables/DataTable";
import { saveAs } from 'file-saver';
import axios from 'axios';
import { useAuthInfo } from "@propelauth/react";
import { utils as XLSXUtils, write as XLSXWrite } from 'xlsx';
import { useBrand } from '../../../BrandContext';

function Results({ modelResults, modelType }) {
    const { selectedBrand, selectedRefresh, user_email } = useBrand();
    const [totalPerformanceData, setTotalPerformanceData] = useState([]);
    const [advertisingPerformanceData, setAdvertisingPerformanceData] = useState([]);
    const [responseCurveData, setResponseCurveData] = useState(null); // Response curve data
    const [renderLimit] = useState(3); // Limit the number of charts rendered
    const [alert, setAlert] = useState({ show: false, message: '', color: '' });
    const [openDialog, setOpenDialog] = useState(false);
    const auth = useAuthInfo();
    const [showResponseCurves, setShowResponseCurves] = useState(false); // Default is to hide response curves
    const [anchorEl, setAnchorEl] = useState(null); // For download menu

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDownloadClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (format) => {
        setAnchorEl(null);
        if (format) {
            downloadData(format);
        }
    };

    useEffect(() => {
        const fetchTotalPerformanceData = async () => {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const model_results_url = `https://storage.cloud.google.com/uploaded_assets/${user_email}/${selectedBrand?.brand_name}/${selectedRefresh?.refresh_name}/${modelType}_outputs.json`;
            const fullUrl = `${baseUrl}/scenarios/get_total_performance?file_url=${encodeURIComponent(model_results_url)}`;
            try {
                const response = await axios.get(fullUrl, {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`
                    },
                });
                const data = response.data;
                setTotalPerformanceData(data.total_performance);
                setAdvertisingPerformanceData(data.advertising_performance);
                setResponseCurveData(data);  // Store the entire merged data for response curves
            } catch (error) {
                console.error('Error getting results model performance and response curve data: ', error);
                setAlert({ show: true, message: `${error.response.data?.detail || error.message}`, color: 'error' });
                setOpenDialog(true);
            }
        };
        fetchTotalPerformanceData();
    }, [modelResults]);

    const downloadData = (format) => {
        const data = [...totalPerformanceData, ...advertisingPerformanceData]; // Combine both tables data

        if (format === 'excel') {
            const worksheet = XLSXUtils.json_to_sheet(data);
            const workbook = XLSXUtils.book_new();
            XLSXUtils.book_append_sheet(workbook, worksheet, 'Results');
            const excelBuffer = XLSXWrite(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(blob, 'results.xlsx');
        } else if (format === 'csv') {
            const worksheet = XLSXUtils.json_to_sheet(data);
            const csv = XLSXUtils.sheet_to_csv(worksheet);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'results.csv');
        }
    };

    // Define table columns for Total Performance
    const totalPerformanceColumns = [
        { Header: <Typography fontWeight="bold" variant="h6">Metric</Typography>, accessor: 'Metric' },
        { Header: <Typography fontWeight="bold" variant="h6">Initial</Typography>, accessor: 'Initial' },
        { Header: <Typography fontWeight="bold" variant="h6">Optimized</Typography>, accessor: 'Optimized' },
    ];

    // Define table columns for Advertising Performance
    const advertisingPerformanceColumns = [
        { Header: <Typography fontWeight="bold" variant="h6">Media Channel</Typography>, accessor: 'MediaChannel' },
        { Header: <Typography fontWeight="bold" variant="h6">Initial Spend</Typography>, accessor: 'InitialSpend' },
        { Header: <Typography fontWeight="bold" variant="h6">Optimized Spend</Typography>, accessor: 'OptimizedSpend' },
    ];

    // Define table rows for Total Performance
    const totalPerformanceRows = totalPerformanceData.map(row => ({
        Metric: row.Metric,
        Initial: row.Initial,
        Optimized: row.Optimized
    }));

    // Define table rows for Advertising Performance
    const advertisingPerformanceRows = advertisingPerformanceData.map(row => ({
        MediaChannel: row.MediaChannel,
        InitialSpend: `$${parseFloat(row.InitialSpend).toLocaleString()}`,
        OptimizedSpend: `$${parseFloat(row.OptimizedSpend).toLocaleString()}`
    }));

    const plotResponseCurves = () => {
    if (!responseCurveData || !showResponseCurves) return null;
        const plots = Object.keys(responseCurveData.spend_response_curves).slice(0, renderLimit).flatMap((target, index) => {
            return Object.keys(responseCurveData.spend_response_curves[target]).map((media_channel) => {
                const mediaData = responseCurveData.spend_response_curves[target][media_channel];
                const spends = mediaData.spend;
                const responses = mediaData.response;

                const averages = responseCurveData.averages_data[target][media_channel];
                const optimized = responseCurveData.optimized_data[target][media_channel];

                return (
                    <Grid item xs={12} md={6} key={`${target}-${media_channel}-${index}`}>
                        <Plot
                            data={[
                                {
                                    x: spends,
                                    y: responses,
                                    mode: 'lines',
                                    name: `${media_channel} curve`,
                                    line: { shape: 'spline', color: 'blue' },  // Smooth curve
                                },
                                {
                                    x: [averages.average_spend],
                                    y: [averages.average_response],
                                    mode: 'markers',
                                    name: 'Average',
                                    marker: { color: 'red', size: 10, symbol: 'circle' }
                                },
                                {
                                    x: [optimized.optimized_spend],
                                    y: [optimized.optimized_response],
                                    mode: 'markers',
                                    name: 'Optimized',
                                    marker: { color: 'green', size: 10, symbol: 'diamond' }
                                }
                            ]}
                            layout={{
                                width: 600,
                                height: 400,
                                title: `Response Curve for ${media_channel} (${target})`,
                                xaxis: { title: 'Spend (with adstock effect)', showgrid: true, zeroline: true },
                                yaxis: { title: 'Response', showgrid: true, zeroline: true },
                                template: 'plotly_white'
                            }}
                        />
                    </Grid>
                );
            });
        });

        return (
            <Grid container spacing={3}>
                {plots}
            </Grid>
        );
    };

    return (
      <>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{alert.color === 'success' ? 'Success' : 'Error'}</DialogTitle>
                <DialogContent>
                    {alert.show && <Alert color={alert.color}>{alert.message}</Alert>}
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}>
                    <Button onClick={handleCloseDialog} color="primary" sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)', } }}>
                        Close
                    </Button>
                </DialogActions>
       </Dialog>
        <Box>
            {/* Total Performance */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4, backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)' }}>
                <Typography variant="h6" sx={{  marginBottom: '5px' }}>
                    Total Performance
                </Typography>
                 <div className="data-table-wrapper">
                    <DataTable
                        table={{ columns: totalPerformanceColumns, rows: totalPerformanceRows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                    />
                 </div>
            </Paper>

           <Paper elevation={3} sx={{ padding: 3, marginBottom: 4, backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)', position: 'relative' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{  marginBottom: '5px' }}>
                        Advertising Performance
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={() => setShowResponseCurves(!showResponseCurves)}>
                            <ShowChartIcon />
                        </IconButton>
                        {/* Download Menu */}
                        <IconButton onClick={handleDownloadClick}>
                            <DownloadIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => handleMenuClose(null)}
                        >
                            <MenuItem onClick={() => handleMenuClose('excel')}>As Excel</MenuItem>
                            <MenuItem onClick={() => handleMenuClose('csv')}>As CSV</MenuItem>
                        </Menu>
                    </Box>
                </Box>
                 <div className="data-table-wrapper">
                    <DataTable
                        table={{ columns: advertisingPerformanceColumns, rows: advertisingPerformanceRows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                    />
                 </div>
            </Paper>
             {plotResponseCurves()}
        </Box>
      </>
    );
}

export default Results;
