import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { useAuthInfo } from "@propelauth/react";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { Alert, Button, DialogContent, Dialog, DialogTitle, DialogActions, CircularProgress } from '@mui/material';
import { blue } from '@mui/material/colors';
import PyMC_png from 'assets/images/pymc.png';
import Robyn_png from 'assets/images/robyn.png';
import Lightweight_png from 'assets/images/lightweight.png';
import OLS_png from 'assets/images/ols.png';
import { useBrand } from '../../../../BrandContext';
import { useDataConfiguration } from 'hooks/useDataConfiguration';

const StartModeling = () => {
    const navigate = useNavigate();
    const { user_email, selectedBrand, selectedRefresh, setSelectedRefresh, configurationData, setConfigurationData, saveContext } = useBrand();
    const [openDialog, setOpenDialog] = useState(false);
    const [loading] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', color: '' });
    const [models, setModels] = useState(() => {
        const savedModels = JSON.parse(localStorage.getItem('models'));
        return savedModels || {
            pymc: { taskID: null, logs: [], completed: false, isConnected: false, downloadLink: '' },
            robyn: { taskID: null, logs: [], completed: false, isConnected: false, downloadLink: '' },
            lightweight: { taskID: null, logs: [], completed: false, isConnected: false, downloadLink: '' },
            ols: { taskID: null, logs: [], completed: false, isConnected: false, downloadLink: '' },
        };
    });

    const {
        fetchDataConfigurationUserSelection,
        getUiConfigurationValues
    } = useDataConfiguration();

     const addLog = async (modelType, logMessage, timestamp) => {
        try {
            const queryParams = new URLSearchParams({
                user_email: user_email,
                brand_name: selectedBrand.brand_name,
                created_at: selectedRefresh.created_at,
                model_type: modelType,
                log_message: logMessage,
                timestamp: timestamp
            }).toString();
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const fullUrl = `${baseUrl}/model/add_log?${queryParams}`;
            await axios.post(fullUrl, {},
                {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`,
                        "Accept": "application/json"
                    }
                }
            );
        } catch (error) {
            console.error("Error adding log:", error);
        }
    };

    const [taskIDs, setTaskIDs] = useState({
        pymc: null,
        robyn: null,
        lightweight: null,
        ols: null,
    });

    const [shouldConnect, setShouldConnect] = useState({
        pymc: false,
        robyn: false,
        lightweight: false,
        ols: false,
    });

    const MAX_RECONNECT_ATTEMPTS = 4;
    const RECONNECT_DELAY = 4000; // 4 seconds between attempts

    const [reconnectAttempts, setReconnectAttempts] = useState({
        pymc: 0,
        robyn: 0,
        lightweight: 0,
        ols: 0,
    });

    useEffect(() => {
        localStorage.setItem('models', JSON.stringify(models));
    }, [models]);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const auth = useAuthInfo();

    const clearLogs = useCallback((modelType) => {
        console.log("Clearing logs for model type: ", modelType);
        setModels((prevModels) => {
            const newModels = {
                ...prevModels,
                [modelType]: {
                    ...prevModels[modelType],
                    logs: [],
                    completed: false,
                    isConnected: false,
                }
            };
            localStorage.setItem('models', JSON.stringify(newModels));
            return newModels;
        });
    }, []);

    const updateLogs = useCallback((modelType, newLog) => {
        setModels(prevModels => {
            const newModels = {
                ...prevModels,
                [modelType]: {
                    ...prevModels[modelType],
                    logs: [...(prevModels[modelType]?.logs || []), newLog],
                }
            };
            localStorage.setItem('models', JSON.stringify(newModels));
            return newModels;
        });
    }, []);

    const handleDataConfiguration = async () =>  {
        if (!selectedBrand || !selectedRefresh) {
            navigate(`/brand-manager`);
        } else {
            const configurationDataUserSelection = await fetchDataConfigurationUserSelection();
            getUiConfigurationValues(configurationDataUserSelection);
            navigate(`/brand-manager/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}/data-configuration`);
        };
    };

    const hasModelResultsForType = useCallback((modelType) => {
        if (!selectedRefresh?.Models_results) {
            return false;
        }
        return selectedRefresh.Models_results.some(file => file.model_type === modelType);
    }, [selectedRefresh]);

    const hasModelRunForType = useCallback((modelType) => {
        const prefix = "https://storage.cloud.google.com/uploaded_assets/";
        const downloadLink = models[modelType].downloadLink?.startsWith(prefix) ? models[modelType].downloadLink.slice(prefix.length) : models[modelType].downloadLink;
        if (downloadLink) {
            const pathParts = downloadLink.split('/');
            const extractedRefreshName = pathParts[pathParts.length - 2];

            if (extractedRefreshName === selectedRefresh.refresh_name) {
                if (!selectedRefresh?.Models_results?.some(file => file.filename === downloadLink && file.model_type === modelType)) {
                    // Instead of updating the state here, defer this update to an effect or a function called later
                    setTimeout(() => {
                        const updatedRefresh = {
                            ...selectedRefresh,
                            has_model_results: true,
                            Models_results: [
                                ...(selectedRefresh?.models_results || []),
                                {
                                    filename: downloadLink,
                                    file_url: `https://storage.cloud.google.com/uploaded_assets/${downloadLink}`,
                                    model_type: modelType
                                }
                            ]
                        };
                        setSelectedRefresh(updatedRefresh);

                        const contextData = {
                            selectedBrand,
                            selectedRefresh: updatedRefresh,
                            configurationData: configurationData
                        };
                        saveContext(contextData);
                    }, 0);  // Use setTimeout to defer state update until after render
                }
                return true;
            }
        }

        if (!selectedRefresh || !selectedRefresh.has_model_results) {
            return false;
        }

        return selectedRefresh?.Models_results?.some(file => file.model_type === modelType && file.refresh_name === selectedRefresh.refresh_name);
    }, [models, selectedBrand, selectedRefresh, configurationData, saveContext]);

    const hasUserSelectionChanged = useCallback((modelType) => {
        // Find the specific model's UserSelection in the selectedRefresh
        const selectedRefreshModel = selectedRefresh?.Models_results?.find(file => file.model_type === modelType);
        if (!selectedRefreshModel) {
            return false;
        }

        // Compare the entire UserSelection of selectedRefresh and configurationData
        const selectedRefreshUserSelection = selectedRefresh?.UserSelection;
        const configurationDataUserSelection = configurationData?.UserSelection;

        //console.log(`UserSelection in selectedRefresh for ${modelType}: `, JSON.stringify(selectedRefreshUserSelection));
        //console.log(`UserSelection in configurationData: `, JSON.stringify(configurationDataUserSelection));

        return JSON.stringify(selectedRefreshUserSelection) !== JSON.stringify(configurationDataUserSelection);
    }, [selectedRefresh, configurationData]);

    const backToModelResults = (modelType) => {
        navigate(`/ai-insights/${modelType}`);
    };

    const updateDownloadLink = useCallback((modelType, downloadLink) => {
        setModels(prevModels => {
            const newModels = {
                ...prevModels,
                [modelType]: {
                    ...prevModels[modelType],
                    downloadLink,
                    completed: true,
                },
            };
            localStorage.setItem('models', JSON.stringify(newModels));
            return newModels;
        });

        const updatedRefresh = {
            ...selectedRefresh,
            UserSelection: configurationData.UserSelection,
        };
        setSelectedRefresh(updatedRefresh);
        saveContext({
            selectedBrand,
            selectedRefresh: updatedRefresh,
            configurationData,
        });

        setAlert({ show: true, message: `Model modeling for ${modelType} completed successfully. You can download the JSON outputs from the logs.`, color: 'success' });
        setOpenDialog(true);
        setShouldConnect((prev) => ({ ...prev, [modelType]: false }));
    }, [selectedRefresh, configurationData, saveContext]);

    const showAlert = (message, color = "info") => {
        setAlert({ show: true, message, color });
        setOpenDialog(true);
    };

    const wsRefs = useRef({ pymc: null, robyn: null, lightweight: null, ols: null });

    const reconnectWebSocket = (modelType) => {
        if (reconnectAttempts[modelType] < MAX_RECONNECT_ATTEMPTS) {
            setTimeout(() => {
                console.log("StartModeling>>index.jsx - Models: ", models);
                console.log("Reconnect Attempts: ", reconnectAttempts[modelType]);
                console.log(`Attempting to reconnect WebSocket for ${modelType}, attempt ${reconnectAttempts[modelType] + 1}`);
                setReconnectAttempts(prev => ({
                    ...prev,
                    [modelType]: prev[modelType] + 1,
                }));
                setShouldConnect((prev) => ({ ...prev, [modelType]: true }));
            }, RECONNECT_DELAY);
        } else {
            console.error(`Max reconnection attempts reached for ${modelType}. No further attempts will be made.`);
        }
    };

    useEffect(() => {
        const currentWsRefs = wsRefs.current;
        Object.keys(shouldConnect).forEach(modelType => {
            if (shouldConnect[modelType] && !currentWsRefs[modelType]) {
                const socketUrl = process.env.REACT_APP_WEB_SOCKET_SERVER;
                const socket = new WebSocket(socketUrl);

                socket.onopen = () => {
                    console.log(`WebSocket connection opened for ${modelType}`);
                    setReconnectAttempts(prev => ({
                        ...prev,
                        [modelType]: 0, // Reset on successful connection
                    }));
                    socket.send(JSON.stringify({ taskId: taskIDs[modelType], modelType }));
                    console.log("StartModeling>>index.jsx - Models: ", models);
                    console.log(`Reconnect Attempts:  ${reconnectAttempts[modelType]} for model ${modelType}`);
                };

                socket.onmessage = (event) => {
                    const log = JSON.parse(event.data);
                    const timestamp = new Date().toLocaleString();
                    console.log("=================================================")
                    console.log(`log.model_type === modelType && log.task_id === taskIDs[modelType] ==> [${log.model_type} === ${modelType} && ${log.task_id} === ${taskIDs[modelType]}]`);
                    console.log(`${timestamp} socket.onmessage: ${log.message} - Type: ${log.model_type} - Task_id: ${log.task_id}`);
                    console.log("=================================================")

                    if (log.model_type === modelType && log.task_id === taskIDs[modelType]) {
                        if (log.message.includes("Download results")) {
                            const downloadLink = log.message.match(/Download results (.+)/)[1];
                            const linkHtml = `<a href="${downloadLink}" target="_blank" rel="noopener noreferrer" style="font-weight: bold; color: blue; padding-left: 10px;">JSON model outputs</a>`;
                            const log_message = `${timestamp} - Model ${modelType} modeling completed. You can download the model outputs here: ${linkHtml} (${timestamp})`;
                            updateLogs(modelType, log_message);
                            updateDownloadLink(modelType, downloadLink);
                            addLog(modelType, log.message, timestamp);
                            showAlert(`${modelType} modeling completed successfully!`, "info");

                            // Close the WebSocket connection after download link is received and processed
                            setShouldConnect((prev) => ({ ...prev, [modelType]: false }));
                            socket.close();
                            console.log(`Download complete - WebSocket connection closed for ${modelType} task_id: ${taskIDs[modelType]}`);
                        } else {
                            updateLogs(log.model_type, log.message);
                            addLog(modelType, `${timestamp} - ${log.message})`, timestamp);
                        }
                        if (log.message.includes("Error")) {
                            updateLogs(modelType, `${timestamp} - ${log.message}`);
                            addLog(modelType, `${timestamp} - ${log.message}`, timestamp);
                            socket.close();
                            currentWsRefs[modelType] = null;
                            setShouldConnect((prev) => ({ ...prev, [modelType]: false }));
                            console.log(`log error - WebSocket connection closed for ${modelType} task_id: ${taskIDs[modelType]}`);
                            showAlert(log.message, "error");
                        }
                        if (log.message.includes("Warning")) {
                            updateLogs(modelType, `${timestamp} - ${log.message}`);
                            addLog(modelType, `${timestamp} - ${log.message}`, timestamp);
                            showAlert(log.message, "warning");
                        }
                    }
                };
                socket.onerror = (error) => {
                    console.error(`WebSocket error for ${modelType}:`, error);
                };
                socket.onclose = () => {
                    console.log(`socket.onclose - WebSocket connection closed for ${modelType}`);
                    currentWsRefs[modelType] = null;

                    // Check if we should reconnect
                    setTimeout(() => {
                        if (shouldConnect[modelType]) {
                            reconnectWebSocket(modelType);  // Attempt to reconnect
                        }
                    }, 0); // Ensure this check is done after state update
                };
                currentWsRefs[modelType] = socket;
            }
        });
        // Clean up WebSocket connections on component unmount
        return () => {
            Object.values(currentWsRefs).forEach(socket => {
                if (socket) {
                    socket.close();
                }
            });
        };
    }, [shouldConnect, taskIDs]); // Run effect when shouldConnect or taskIDs change

    const runModel = async (modelType) => {
        const taskID = uuidv4();
        const queryParams = new URLSearchParams({
            file_name: selectedRefresh.selectedFile.file.file_url,
            brand: selectedBrand.brand_name,
            refresh_name: selectedRefresh.refresh_name,
            user_selection: JSON.stringify(configurationData.UserSelection),
            task_id: taskID,
            model_type: modelType
        }).toString();
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/model/start_modeling?${queryParams}`;
        try {
            clearLogs(modelType);
            setTaskIDs((prevTaskIDs) => ({
                ...prevTaskIDs,
                [modelType]: taskID,
            })); // Set the new task ID before making the request
            setModels(prevModels => {
                const newModels = {
                    ...prevModels,
                    [modelType]: {
                        ...prevModels[modelType],
                        taskID: taskID,
                    }
                };
                localStorage.setItem('models', JSON.stringify(newModels));
                return newModels;
            });
            const timestamp = new Date().toLocaleString();
            setShouldConnect((prev) => ({ ...prev, [modelType]: true }));
            updateLogs(modelType, `${timestamp} - Starting modeling...`);
            addLog(modelType, `${timestamp} - Starting modeling...`, timestamp);

            const response = await axios.post(
                fullUrl, {},
                {
                    headers: {
                        "Authorization": `Bearer ${auth.accessToken}`,
                        "Accept": "application/json"
                    }
                }
            );
            if (response.status === 200) {
                const log_message = `${timestamp} - Modeling for ${modelType} for brand ${selectedBrand.brand_name} has started.\nTask id: ${taskID} - Model type: ${modelType}`;
                updateLogs(modelType, log_message);
                addLog(modelType, log_message, timestamp);

            } else {
                showAlert(`Error running model: ${response.statusText || 'Unknown error'}`, 'error');
                setShouldConnect((prev) => ({ ...prev, [modelType]: false }));
            }

        } catch (error) {
            let errorMessage = 'Error running model';
            if (error.response && error.response.data && error.response.data.detail) {
                errorMessage = `Error running model: ${error.response.data.detail}`;
            } else if (error.message) {
                errorMessage = `Error running model: ${error.message}`;
            }
            showAlert(errorMessage, 'error');
            setShouldConnect((prev) => ({ ...prev, [modelType]: false }));
        }
    };

    return (
        <>
            <Dialog open={openDialog} aria-labelledby="Modeling" aria-describedby="run-models" onClose={handleCloseDialog}>
                <DialogTitle>Modeling Status</DialogTitle>
                <DialogContent>
                    {alert.show && <Alert severity={alert.color}>{alert.message}</Alert>}
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {loading ? (
                <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress sx={{ color: blue[500] }} />
                    <MDTypography variant="normal" sx={{ mt: 2 }}>
                        Loading data configuration for <strong>{selectedRefresh?.refresh_name}</strong>...
                    </MDTypography>
                </MDBox>
            ) : (
            <MDBox sx={{ width: { xs: '100%', sm: 600 }, p: { xs: 2, sm: 0 } }}>
                <MDTypography component="div" sx={{ mt: 2, width: '100%', textAlign: 'center' }} variant="body2">
                    Select Model to Run for <strong>{selectedRefresh?.selectedFile?.file.file_name}</strong>
                </MDTypography>
                <MDBox sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', margin: '20px 0' }}>
                    <MDBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src={PyMC_png} alt="PyMC" style={{ width: '50px', marginRight: '10px' }} />
                            <MDTypography variant="h6" component="div">PyMC</MDTypography>
                        </MDBox>
                        <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                            <MDButton variant="contained" color="dark" onClick={() => runModel('pymc')} sx={{ mr: 1 }} disabled={hasUserSelectionChanged('pymc') || !selectedRefresh?.selectedFile || hasModelRunForType('pymc')}>Run Model</MDButton>
                            <MDButton variant="contained" disabled={!hasModelResultsForType('pymc')} color="dark" onClick={() => backToModelResults('pymc')}>Last Run Results</MDButton>
                        </MDBox>
                    </MDBox>
                    {models['pymc'] && models['pymc'].logs && (
                        <MDBox sx={{ marginBottom: '20px', maxHeight: '200px', overflowY: 'scroll', border: '1px dotted lightgrey', padding: '7px' }}>
                            <MDTypography variant="normal" component="div">Logs</MDTypography>
                            <MDBox component="ul" sx={{ paddingLeft: '20px', textAlign: 'left' }}>
                                {models['pymc'].logs.map((log, index) => (
                                    <MDTypography variant="caption" component="div">
                                        <li key={index}>
                                            <div dangerouslySetInnerHTML={{ __html: log }}></div>
                                        </li>
                                    </MDTypography>
                                ))}
                            </MDBox>
                        </MDBox>
                    )}
                    <MDBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src={Robyn_png} alt="Robyn" style={{ width: '50px', marginRight: '10px' }} />
                            <MDTypography variant="h6" component="div">Robyn</MDTypography>
                        </MDBox>
                        <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                            <MDButton variant="contained" color="dark" onClick={() => runModel('robyn')} sx={{ mr: 1 }} disabled={hasUserSelectionChanged('robyn') || !selectedRefresh?.selectedFile || hasModelRunForType('robyn')}>Run Model</MDButton>
                            <MDButton variant="contained" disabled={!hasModelResultsForType('robyn')} color="dark" onClick={() => backToModelResults('robyn')}>Last Run Results</MDButton>
                        </MDBox>
                    </MDBox>
                    {models['robyn'] && models['robyn'].logs && (
                        <MDBox sx={{ marginBottom: '20px', maxHeight: '200px', overflowY: 'scroll', border: '1px dotted lightgrey', padding: '7px' }}>
                            <MDTypography variant="normal" component="div">Logs</MDTypography>
                            <MDBox component="ul" sx={{ paddingLeft: '20px', textAlign: 'left' }}>
                                {models['robyn'].logs.map((log, index) => (
                                    <MDTypography variant="caption" component="div">
                                        <li key={index}>
                                            <div dangerouslySetInnerHTML={{ __html: log }}></div>
                                        </li>
                                    </MDTypography>
                                ))}
                            </MDBox>
                        </MDBox>
                    )}
                    <MDBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src={Lightweight_png} alt="Lightweight" style={{ width: '50px', marginRight: '10px' }} />
                            <MDTypography variant="h6" component="div">Lightweight</MDTypography>
                        </MDBox>
                        <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                            <MDButton variant="contained" color="dark" onClick={() => runModel('lightweight')} sx={{ mr: 1 }} disabled={hasUserSelectionChanged('lightweight') || !selectedRefresh?.selectedFile || hasModelRunForType('lightweight')}>Run Model</MDButton>
                            <MDButton variant="contained" disabled={!hasModelResultsForType('lightweight')} color="dark" onClick={() => backToModelResults('lightweight')}>Last Run Results</MDButton>
                        </MDBox>
                    </MDBox>
                    {models['lightweight'] && models['lightweight'].logs && (
                        <MDBox sx={{ marginBottom: '20px', maxHeight: '200px', overflowY: 'scroll', border: '1px dotted lightgrey', padding: '7px' }}>
                            <MDTypography variant="normal" component="div">Logs</MDTypography>
                            <MDBox component="ul" sx={{ paddingLeft: '20px', textAlign: 'left' }}>
                                {models['lightweight'].logs.map((log, index) => (
                                    <MDTypography variant="caption" component="div">
                                        <li key={index}>
                                            <div dangerouslySetInnerHTML={{ __html: log }}></div>
                                        </li>
                                    </MDTypography>
                                ))}
                            </MDBox>
                        </MDBox>
                    )}
                    <MDBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                        <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                            <img src={OLS_png} alt="OLS" style={{ width: '50px', marginRight: '10px' }} />
                            <MDTypography variant="h6" component="div">OLS</MDTypography>
                        </MDBox>
                        <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                            <MDButton variant="contained" color="dark" onClick={() => runModel('ols')} sx={{ mr: 1 }} disabled={hasUserSelectionChanged('ols') || !selectedRefresh?.selectedFile || hasModelRunForType('ols')}>Run Model</MDButton>
                            <MDButton variant="contained" disabled={!hasModelResultsForType('ols')} color="dark" onClick={() => backToModelResults('ols')}>Last Run Results</MDButton>
                        </MDBox>
                    </MDBox>
                    {models['ols'] && models['ols'].logs && (
                        <MDBox sx={{ marginBottom: '20px', maxHeight: '200px', overflowY: 'scroll', border: '1px dotted lightgrey', padding: '7px' }}>
                            <MDTypography variant="normal" component="div">Logs</MDTypography>
                            <MDBox component="ul" sx={{ paddingLeft: '20px', textAlign: 'left' }}>
                                {models['ols'].logs.map((log, index) => (
                                    <MDTypography variant="caption" component="div">
                                        <li key={index}>
                                            <div dangerouslySetInnerHTML={{ __html: log }}></div>
                                        </li>
                                    </MDTypography>
                                ))}
                            </MDBox>
                        </MDBox>
                    )}
                </MDBox>
                <MDTypography component="div" sx={{ mb: 2, width: '100%', textAlign: 'center' }} variant="h4">
                    <MDButton
                        variant="contained"
                        disabled={!selectedBrand || !selectedRefresh || !selectedRefresh.selectedFile}
                        color="dark"
                        onClick={handleDataConfiguration}
                        sx={{ alignSelf: 'center', mb: '5px' }}
                    >
                        Data Configuration
                    </MDButton>
                </MDTypography>
            </MDBox>
            )}
        </>
    );
};

export default StartModeling;
