import { useEffect } from "react";
import logo from 'assets/images/mmmlabs-logo.png';
import 'assets/css/app.css';
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SidenavCollapse from "assets/Sidenav/SidenavCollapse";
import SidenavRoot from "assets/Sidenav/SidenavRoot";
import { useBrand } from 'BrandContext';
import { useAuthInfo } from "@propelauth/react";


import {
  useMaterialUIController,
  setMiniSidenav,
} from "context";

function Sidenav({ color = 'info', brand = "", brandName, routes, userRole, props = { user: { email: "" } }, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const { setUserEmail } = useBrand();
  const { setUserCompany } = useBrand();

  const auth = useAuthInfo();
  const orgId = Object.keys(auth.userClass.orgIdToUserOrgInfo)[0];
  const org = (auth.userClass.orgIdToUserOrgInfo[orgId]).orgName;

  let textColor = "white";
  textColor = "dark";

  useEffect(() => {
    // Set the user email and company in the BrandContext
    setUserEmail(props.user.email);
    setUserCompany(org);

    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location, setUserEmail, setUserCompany, org, props.user.email]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={true}
        />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
             <img src={logo} alt="MMM Labs" className="logo" />
          {brand && <MDBox width="2rem" />}
        </MDBox>
          <MDTypography fontSize="1.4rem" fontWeight="medium" color={textColor} >
             {brandName}
          </MDTypography>
      </MDBox>
      <Divider
        light={true}
      />
      <List>{renderRoutes}</List>
      <Divider
        light={false}
      />
      <MDTypography  variant="caption" textAlign="center">
            <b>{props.user.firstName} {props.user.lastName}</b>
            <br/> is logged in with username<br/>
            <b>{props.user.email}</b>
            <br/>from<br/>
            <b>{org}</b>
       </MDTypography>
    </SidenavRoot>
  );
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
