import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import { Box, Typography, Breadcrumbs, Link } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import { useNavigate } from 'react-router-dom';
import Footer from "assets/Footer";
import SalesTab from 'layouts/data-validation/components/SalesTab';
import MarketingTab from 'layouts/data-validation/components/MarketingTab';
import OthersTab from 'layouts/data-validation/components/OthersTab';
import DataLogTab from 'layouts/data-validation/components/DataLogTab';
import { useBrand } from '../../BrandContext';


function DataValidation() {
  const { selectedBrand, selectedRefresh, selectedFile } = useBrand();
  const [tabValue, setTabValue] = useState(0);
  const { brandName, refreshName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedFile) {
      localStorage.clear();
    }
  }, [selectedFile]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const tabStyle = (index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  });

  const breadcrumb = [
      ...(selectedBrand ? [{ label: `Brand: ${selectedBrand.brand_name}`, link: `/brand-manager/${selectedBrand.brand_name}` }] : [{label: `No brand selected`}]),
      ...(selectedRefresh ? [{ label: `Refresh: ${selectedRefresh.refresh_name}`, link: `/brand-manager/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}` }] : [])
  ];

  const renderBreadcrumbs = () => (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {breadcrumb.map((item, index) => (
        <Typography key={index} color="inherit" variant="normal">
          {item.link ? (
            <Link
              color="inherit"
              onClick={() => navigate(item.link)}
              sx={{ fontWeight: 'bold', cursor: 'pointer' }}
            >
              {item.label}
            </Link>
          ) : (
            <span sx={{ fontWeight: 'bold' }}>
              {item.label}
            </span>
          )}
        </Typography>
      ))}
    </Breadcrumbs>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AppBar position="static">
        <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
          <Tab
            label="Sales"
            icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>leaderboard</Icon>}
            style={tabStyle(0)}
          />
          <Tab
            label="Marketing"
            icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>stacked_line_chart</Icon>}
            style={tabStyle(1)}
          />
          <Tab
            label="Others"
            icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>miscellaneous_services</Icon>}
            style={tabStyle(2)}
          />
          <Tab
            label="Data Log"
            icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>view_list</Icon>}
            style={tabStyle(3)}
          />
        </Tabs>
      </AppBar>
      <Box sx={{ p: 2, pl: 1, mt: 1 }}>
            {renderBreadcrumbs()}
       </Box>
      {tabValue === 0 && <SalesTab selectedFile={selectedRefresh?.selectedFile}  />}
      {tabValue === 1 && <MarketingTab selectedFile={selectedRefresh?.selectedFile} />}
      {tabValue === 2 && <OthersTab selectedFile={selectedRefresh?.selectedFile} />}
      {tabValue === 3 && <DataLogTab selectedFile={selectedRefresh?.selectedFile} />}
      <Footer />
    </DashboardLayout>
  );
}

export default DataValidation;
