import React, { useState, useEffect, useCallback, useContext } from 'react';
import ChatBar from 'layouts/ChatBar/ChatBar';
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import OthersSummaryTable from './OthersSummaryTable';
import OthersGraph from './OthersGraph';
import GetFileData from './../GetFileData';
import { useBrand } from '../../../../BrandContext';

const OthersTab = ( { selectedFile = null }) => {
    // Initialize state with data from localStorage if available
    const [data, setData] = useState(() => {
        const savedData = localStorage.getItem('othersData');
        return savedData ? JSON.parse(savedData) : null;
    });
    // Update localStorage when data changes
    useEffect(() => {
        localStorage.setItem('othersData', JSON.stringify(data));
    }, [data]);

    const onGetData = useCallback((sheetName, data) => {
        setData(data);
        console.log("Data: ", data);
    }, []);

    return (
        <>
            <MDBox>
                <MDTypography variant="h3">
                    Others
                </MDTypography>
                <MDTypography variant="body2" mt={2} mb={5} fontSize="0.9rem">
                    File selected: <b>{selectedFile?.file?.file_name || "No file selected"}</b>
                </MDTypography>
                {selectedFile && (
                    <GetFileData
                        sheetName="Others"
                        onGetData={onGetData}
                        file={selectedFile}
                    />
                )}
               <MDTypography variant="normal" fontWeight="normal">
                  Coming soon...
              </MDTypography>
{/*                 <OthersSummaryTable data={data} /> */}
{/*                 <Divider /> */}
{/*                 <OthersGraph data={data} /> */}
            </MDBox>
        </>
    );
};

export default OthersTab;