import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useBrand } from '../../../../../BrandContext';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 205,
    },
  },
};

function getStyles(name, adsVariable, theme) {
  return {
    fontWeight:
      adsVariable.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AdsVariable({ ads_variable = [] }) {
  const theme = useTheme();
  const { configurationDataUserSelection, updateConfigurationDataUserSelection } = useBrand();

  if (!Array.isArray(ads_variable)) {
    ads_variable = [];
  }

  const filterValidVariables = (variables) => Array.isArray(variables) ? variables.filter(variable => ads_variable.includes(variable)) : [];

  const [adsVariable, setAdsVariable] = useState(() => {
    const initialVariables = configurationDataUserSelection?.adsVariable && configurationDataUserSelection.adsVariable.length > 0 ? configurationDataUserSelection.adsVariable : [];
    return filterValidVariables(initialVariables.length ? initialVariables : ads_variable.length ? [ads_variable[0]] : []);
  });

  useEffect(() => {
    const initialVariables = configurationDataUserSelection?.adsVariable && configurationDataUserSelection.adsVariable.length > 0 ? configurationDataUserSelection.adsVariable : ads_variable.length ? [ads_variable[0]] : [];
    const validInitialVariables = filterValidVariables(initialVariables);
    setAdsVariable(validInitialVariables);
    updateConfigurationDataUserSelection('adsVariable', validInitialVariables);
  }, [ads_variable, configurationDataUserSelection?.adsVariable, updateConfigurationDataUserSelection]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const newValue = Array.isArray(value) ? value : value.split(',');
    const validValues = filterValidVariables(newValue);

    setAdsVariable(validValues);
    updateConfigurationDataUserSelection('adsVariable', validValues);
  };

  return (
    <FormControl sx={{ m: 1, width: 205 }}>
      <InputLabel id="ads-variable-label">Variable</InputLabel>
      <Select
        sx={{ margin: '8px', padding: '5px 0' }}
        labelId="ads-variable-label"
        id="multiple-ads-variable"
        multiple
        value={adsVariable.length > 0 ? adsVariable : []}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-ads-variable" label="Variable" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} sx={{ height: '10px', padding: '8px 1px' }} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {ads_variable.length > 0 ? (
          ads_variable.map((name) => (
            <MenuItem
              sx={{ height: '15px', padding: '10px', marginTop: '5px' }}
              key={name}
              value={name}
              style={getStyles(name, adsVariable, theme)}
            >
              {name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No options available</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
