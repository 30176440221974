import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import Footer from "assets/Footer";

import Brands from './components/Brands';
import BrandDetails from './components/BrandDetails';
import RefreshDetails from './components/RefreshDetails';
import DataConfiguration from './components/DataConfiguration';

function BrandManager() {
    const [tabValue, setTabValue] = useState(0);
    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
    };
    const tabStyle = (index) => ({
        border: tabValue === index ? '2px solid #247EEA' : 'none'
    });

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <AppBar position="static">
                <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                    <Tab
                        label="Brands Setup"
                        icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>dashboard_customize</Icon>}
                        style={tabStyle(0)}
                    />
                </Tabs>
            </AppBar>
            {tabValue === 0 && (
                <Routes>
                    <Route path="/" element={<Brands />} />
                    <Route path="/:brandName" element={<BrandDetails />} />
                    <Route path="/:brandName/:refreshName" element={<RefreshDetails />} />
                    <Route path="/:brandName/:refreshName/data-configuration" element={<DataConfiguration />} />
                </Routes>
            )}
            <br/>
            <Footer />
        </DashboardLayout>
    );
}

export default BrandManager;
