import React, { useState, useContext } from 'react';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "assets/Footer";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Link, Typography, Box } from '@mui/material';
import { useBrand } from '../../BrandContext';
import ModelingSetupTab from 'layouts/modeling/components/ModelingSetupTab';


function Modeling() {
  const { selectedBrand, selectedRefresh, selectedFile } = useBrand();
  const location = useLocation();
  const initialState = location.state ? location.state.state : {};
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };
  const tabStyle = (index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  });

  const navigate = useNavigate();

  const breadcrumb = [
      ...(selectedBrand ? [{ label: `Brand: ${selectedBrand.brand_name}`, link: `/brand-manager/${selectedBrand.brand_name}` }] : [{label: `No brand selected`}] ),
      ...(selectedRefresh ? [{ label: `Refresh: ${selectedRefresh.refresh_name}`, link: `/brand-manager/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}` }] : []),
      ...(selectedFile? [{ label: `File selected: ${selectedFile.file.file_name}`, link: `/brand-manager/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}` }] : [])
  ];

  const renderBreadcrumbs = () => (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {breadcrumb.map((item, index) => (
        <Typography key={index} color="inherit" variant="normal">
          {item.link ? (
            <Link
              color="inherit"
              onClick={() => navigate(item.link)}
              sx={{ fontWeight: 'bold', cursor: 'pointer' }}
            >
              {item.label}
            </Link>
          ) : (
            <span sx={{ fontWeight: 'bold' }}>
              {item.label}
            </span>
          )}
        </Typography>
      ))}
    </Breadcrumbs>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <AppBar position="static">
              <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Modeling Setup"
                  icon={ <Icon fontSize="small" sx={{ mt: -0.25 }}>dataset</Icon> }
                  style={tabStyle(0)}
                />
                 </Tabs>
          </AppBar>
          <MDBox sx={{ p: 2, pl: 1, mt: 1 }}>
            {renderBreadcrumbs()}
         </MDBox>
         {tabValue === 0 && <ModelingSetupTab initialState={initialState} />}
      <Footer />
    </DashboardLayout>
  );
}

export default Modeling;
