import React from 'react';
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';
import ChatBar from 'layouts/ChatBar/ChatBar';
import modelDetails1 from "assets/images/modelDetails1.png";
import modelDetails2 from "assets/images/modelDetails2.png";


const ChannelDiscoveryTab = () => {
    return (
      <MDBox pt={6} pb={3} pr={5}>
            <MDTypography variant="h3" >
                Channel Discovery
            </MDTypography>
{/*             <ChatBar /> */}
            <br/>
            <Card>
            <MDTypography variant="body2" p={2}>
              TBD
            </MDTypography>
          </Card>
        </MDBox>
    );
};

export default ChannelDiscoveryTab;