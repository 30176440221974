import { useState, useEffect, useContext, createContext, useCallback, useMemo } from 'react';
import axios from 'axios';

const SignedUrlContext = createContext();

export const SignedUrlProvider = ({ children }) => {
  const [signedUrls, setSignedUrls] = useState({});

  const getSignedUrl = useCallback(async (fileUrl) => {
    if (signedUrls[fileUrl]) {
      return signedUrls[fileUrl];
    }

    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const fullUrl = `${baseUrl}/utils/generate_signed_url`;
    try {
      const response = await axios.get(fullUrl, {
        params: { file_url: fileUrl },
      });

      let { signed_url } = response.data;
      //console.log('Generated signed URL:', signed_url);
      //signed_url = signed_url.replace('https://storage.googleapis.com/', 'https://storage.cloud.google.com/');
      //const { signed_url } = response.data;
      setSignedUrls((prevUrls) => ({ ...prevUrls, [fileUrl]: signed_url }));
      return signed_url;

    } catch (error) {
      console.error('Error fetching signed URL:', error);
      return null;
    }
  }, [signedUrls]);

  const value = useMemo(() => ({ getSignedUrl }), [getSignedUrl]);

  return (
    <SignedUrlContext.Provider value={value}>
      {children}
    </SignedUrlContext.Provider>
  );
};

export const useSignedUrl = (filePath) => {
  const { getSignedUrl } = useContext(SignedUrlContext);
  const [signedUrl, setSignedUrl] = useState('');

  useEffect(() => {
    const fetchSignedUrl = async () => {
      if (filePath) {
        const relativePath = filePath.split('uploaded_assets/').pop();
        const url = await getSignedUrl(relativePath);
        //console.log('Fetched signed URL:', url);
        setSignedUrl(url);
      }
    };
    fetchSignedUrl();
  }, [filePath, getSignedUrl]);

  return signedUrl;
};
