import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import axios from 'axios';

// Create the context
const BrandContext = createContext();

// Default UserSelection configuration
const defaultUserSelection = {
  adsChannels: '',
  adsModeledMetric: '',
  adsSpendMetric: '',
  adsVariable: '',
  date: '',
  organicVariables: '',
  salesChannels: '',
  salesMetric: '',
  salesOthers: '',
  salesPrice: '',
  salesVariable: '',
  uncontrollableVariables: '',
};

// Create a provider component
export const BrandProvider = ({ children }) => {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedRefresh, setSelectedRefreshState] = useState(null);
  const [user_email, setUserEmail] = useState(null);
  const [user_company, setUserCompany] = useState(null);
  const [configurationData, setConfigurationData] = useState({
    UserSelection: { ...defaultUserSelection },
  });

  const updateConfigurationDataUserSelection = (key, value) => {
    setConfigurationData((prevData) => {
      const updatedData = {
        ...prevData,
        UserSelection: {
          ...prevData?.UserSelection,
          [key]: value,
        },
      };

      if (selectedRefresh) {
        const updatedRefresh = {
          ...selectedRefresh,
          UserSelection: updatedData?.UserSelection,
        };
        setSelectedRefresh(updatedRefresh);

        if (selectedBrand) {
          const updatedRefreshes = selectedBrand.refreshes.map((refresh) =>
            refresh.created_at === updatedRefresh.created_at ? updatedRefresh : refresh
          );
          const updatedBrand = {
            ...selectedBrand,
            refreshes: updatedRefreshes,
          };
          setSelectedBrand(updatedBrand);
        }
      }
      return updatedData;
    });
  };

  const loadContext = useCallback(async (brand) => {
    console.log("Loading context for brand: ", brand);
    if (user_email && user_company && brand) {
      localStorage.clear();
      try {
        const queryParams = new URLSearchParams({
          email: user_email,
          company: user_company,
          brand: brand.brand_name,
        }).toString();
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/brands/load_context?${queryParams}`;
        const response = await axios.post(fullUrl, {}, {
          headers: { "Accept": "application/json" },
        });
        if (response.data) {
          console.log("BrandContext>>loadContext - ", response.data);
          setSelectedBrand(response.data.selectedBrand || null);
          const updatedRefresh = {
            ...response.data.selectedRefresh,
            selectedFile: response.data.selectedFile || null,
          };
          setSelectedRefresh(updatedRefresh);
          setConfigurationData(response.data.configurationData || {
            UserSelection: { ...defaultUserSelection },
          });
        }
      } catch (error) {
        console.error("Error loading context:", error);
      }
    }
  }, [user_email, user_company]);

  // Enhanced setSelectedRefresh function
  const setSelectedRefresh = (refresh, file = null) => {
    const updatedRefresh = file ? { ...refresh, selectedFile: file } : refresh;

    setSelectedRefreshState(updatedRefresh);

    if (selectedBrand) {
      const updatedRefreshes = selectedBrand.refreshes.map((r) =>
        r.created_at === updatedRefresh.created_at ? updatedRefresh : r
      );
      const updatedBrand = {
        ...selectedBrand,
        refreshes: updatedRefreshes,
      };
      setSelectedBrand(updatedBrand);
    }
  };

  // Save context to the backend with parameters
  const saveContext = useCallback(async (contextData) => {
    //console.log("BrandContext>>saveContext - configurationData", JSON.stringify(contextData.configurationData, null, 2));
    if (user_email && user_company && selectedBrand && contextData) {
      try {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/brands/save_context`;
        await axios.post(fullUrl, {
          email: user_email,
          company: user_company,
          brand: selectedBrand.brand_name,
          context: contextData,
        }, {
          headers: { "Accept": "application/json" },
        });
      } catch (error) {
        console.error("Error saving context:", error);
      }
    }
  }, [user_email, user_company, selectedBrand, selectedRefresh, configurationData]);

  useEffect(() => {
    localStorage.clear();
    loadContext();
  }, [user_email, user_company, loadContext]);

  return (
    <BrandContext.Provider value={{
      selectedBrand, setSelectedBrand,
      selectedRefresh, setSelectedRefresh,
      user_email, setUserEmail,
      user_company, setUserCompany,
      configurationData, setConfigurationData,
      configurationDataUserSelection: configurationData?.UserSelection,
      updateConfigurationDataUserSelection,
      saveContext,
      loadContext,
    }}>
      {children}
    </BrandContext.Provider>
  );
};

// Create a custom hook to use the BrandContext
export const useBrand = () => {
  return useContext(BrandContext);
};
