import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Tabs, Tab, Breadcrumbs, Link, Icon, Typography, Card, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Button } from '@mui/material';
import NameAndSettings from './NameAndSettings';
import Constraints from './Constraints';
import Results from './Results';
import axios from 'axios';
import ScenarioTabs from './ScenarioTabs';
import MDBox from "components/MDBox";
import Footer from "assets/Footer";
import AppBar from "@mui/material/AppBar";
import DashboardLayout from "assets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "assets/Navbars/DashboardNavbar";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useAuthInfo } from "@propelauth/react";
import { useBrand } from '../../../BrandContext';

function ScenarioDetails({ onUpdateScenario, refreshes, modelTypes }) {
  const { selectedBrand, selectedRefresh } = useBrand();
  const location = useLocation();
  const navigate = useNavigate();
  const scenario = location.state?.scenario || {};
  const auth = useAuthInfo();
  const [selectedTab, setSelectedTab] = useState(0);
  const [alert, setAlert] = useState({ show: false, message: '', color: '' });
  const [openDialog, setOpenDialog] = useState(false);
  const [constraints, setConstraints] = useState(scenario.constraints || []);
  const [totalBudget, setTotalBudget] = useState(scenario.budget || 100000);
  const [budgetTimingStart, setBudgetTimingStart] = useState(scenario.budgetTimingStart || null);
  const [budgetTimingEnd, setBudgetTimingEnd] = useState(scenario.budgetTimingEnd || null);
  const [scenarioName, setScenarioName] = useState(scenario.scenario || '');
  const [scenarioDescription, setScenarioDescription] = useState(scenario.description || '');
  const [scenarioModelType, setScenarioModelType] = useState(scenario.modelType || '');
  const [scenarioObjective, setScenarioObjective] = useState(scenario.scenarioGoal || '');
  const [optimizationGoal, setOptimizationGoal] = useState(scenario.optimizationGoal || '');
  const [tabValue, setTabValue] = useState(0);
  const [modelResults, setModelResults] = useState(null);

  const optimizedAdSpend = 110000; // Placeholder value, replace with actual logic

  const handleBackToScenarios = () => {
    navigate('/scenario-planner');
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  const tabStyle = (index) => ({
    border: tabValue === index ? '2px solid #247EEA' : 'none'
  });

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleObjectiveChange = (event) => {
    setScenarioObjective(event.target.value);
  };

  const handleOptimizationGoalChange = (event) => {
    setOptimizationGoal(event.target.value);
  };

  useEffect(() => {
    // Set the tab based on the state when navigating
    if (location.state?.selectedTab !== undefined) {
      setSelectedTab(location.state.selectedTab);
    }

    const fetchModelResults = async () => {
      if (!scenarioModelType) {
        return;
      }
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const fullUrl = `${baseUrl}/scenarios/get_model_results?brand_name=${encodeURIComponent(selectedBrand.brand_name)}&refresh_name=${encodeURIComponent(selectedRefresh.refresh_name)}&model_type=${encodeURIComponent(scenarioModelType)}`;

      try {
        const response = await axios.get(fullUrl, {
          headers: {
            "Authorization": `Bearer ${auth.accessToken}`
          },
        });

        const data = response.data.data;
        setModelResults(data);

        if (data.revenue && data.revenue.result && data.revenue.result.x_input_interval_nontransformed) {
          const dates = data.revenue.result.x_input_interval_nontransformed.map(entry => new Date(entry.date));
          const minDate = new Date(Math.min(...dates));
          const maxDate = new Date(Math.max(...dates));
          if (!budgetTimingStart && !budgetTimingEnd) {
            setBudgetTimingStart(minDate);
            setBudgetTimingEnd(maxDate);
          }
        }
      } catch (error) {
        console.error('Error getting model results:', error);
        setAlert({ show: true, message: `${error.response.data.detail}`, color: 'error' });
        setOpenDialog(true);
      }
    };

    if (selectedBrand && selectedRefresh) {
      fetchModelResults();
    }

    if (onUpdateScenario) {
      onUpdateScenario({
        ...scenario,
        scenario: scenarioName,
        description: scenarioDescription,
        scenarioGoal: scenarioObjective,
        optimizationGoal: optimizationGoal,
        budgetTimingStart,
        budgetTimingEnd,
      });
    }
  }, [scenarioName, scenarioDescription, scenarioObjective, optimizationGoal, budgetTimingStart, budgetTimingEnd, location.state?.selectedTab]);

  const breadcrumb = [
    ...(selectedBrand ? [{ label: `Brand: ${selectedBrand.brand_name}`, link: `/brand-manager/${selectedBrand.brand_name}` }] : [{ label: `No brand selected` }]),
    ...(selectedRefresh ? [{ label: `Refresh: ${selectedRefresh.refresh_name || 'None'}`, link: `/brand-manager/${selectedBrand.brand_name}/${selectedRefresh.refresh_name || 'None'}` }] : [{ label: `Refresh: None` }]),
    ...(selectedRefresh ? [{ label: `File selected: ${selectedRefresh.selectedFile?.file?.file_name || 'None'}`, link: `/brand-manager/${selectedBrand.brand_name}/${selectedRefresh?.refresh_name || 'None'}` }] : [{ label: `File selected: None` }])
  ];

  const renderBreadcrumbs = () => (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {breadcrumb.map((item, index) => (
        <Typography key={index} color="inherit" variant="normal">
          {item.link ? (
            <Link
              color="inherit"
              onClick={() => navigate(item.link)}
              sx={{ fontWeight: 'bold', cursor: 'pointer' }}
            >
              {item.label}
            </Link>
          ) : (
            <span sx={{ fontWeight: 'bold' }}>
              {item.label}
            </span>
          )}
        </Typography>
      ))}
    </Breadcrumbs>
  );

  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{alert.color === 'success' ? 'Success' : 'Error'}</DialogTitle>
        <DialogContent>
          {alert.show && <Alert color={alert.color}>{alert.message}</Alert>}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} color="primary" sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)', } }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <DashboardLayout>
        <DashboardNavbar />
        <AppBar position="static">
          <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
            <Tab
              label={`${scenario.scenario} Details`}
              icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>business_center</Icon>}
              sx={tabStyle(0)}
            />
          </Tabs>
        </AppBar>
        <MDBox sx={{ p: 2, pl: 1, mt: 1 }}>
          {renderBreadcrumbs()}
        </MDBox>
        <Card>
          <Box>
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Scenario Detail Tabs">
              <Tab
                label={<Typography variant="h5">Name & Settings</Typography>}
              />
              <Tab
                label={<Typography variant="h5">Constraints</Typography>}
              />
              <Tab
                label={<Typography variant="h5">Results</Typography>}
              />
            </Tabs>

            {selectedTab === 0 && (
              <Box padding={2}>
                <NameAndSettings
                  scenarioName={scenarioName}
                  setScenarioName={setScenarioName}
                  scenarioDescription={scenarioDescription}
                  setScenarioDescription={setScenarioDescription}
                  setScenarioModelType={setScenarioModelType}
                  scenarioModelType={scenarioModelType}
                  scenarioObjective={scenarioObjective}
                  setScenarioObjective={setScenarioObjective}
                  optimizationGoal={optimizationGoal}
                  setOptimizationGoal={setOptimizationGoal}
                  handleObjectiveChange={handleObjectiveChange}
                  handleOptimizationGoalChange={handleOptimizationGoalChange}
                  budgetTimingStart={budgetTimingStart}
                  setBudgetTimingStart={setBudgetTimingStart}
                  budgetTimingEnd={budgetTimingEnd}
                  setBudgetTimingEnd={setBudgetTimingEnd}
                  refreshName={scenario.refresh}
                  refreshes={refreshes}
                  modelTypes={modelTypes}
                />
              </Box>
            )}

            {selectedTab === 1 && (
              <Box padding={2}>
                <Constraints
                  constraints={constraints}
                  setConstraints={setConstraints}
                  totalBudget={totalBudget}
                  setTotalBudget={setTotalBudget}
                  budgetTimingStart={budgetTimingStart}
                  budgetTimingEnd={budgetTimingEnd}
                  modelType={scenarioModelType}
                  modelResults={modelResults}
                />
              </Box>
            )}

            {selectedTab === 2 && (
              <Box padding={2}>
                <Results
                  initialData={{ adSpend: totalBudget }}
                  optimizedData={{ adSpend: optimizedAdSpend }}
                  constraints={constraints}
                  modelType={scenarioModelType}
                  modelResults={modelResults}
                />
              </Box>
            )}

            <ScenarioTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              handleBackToScenarios={handleBackToScenarios}
            />
          </Box>
        </Card>
        <br />
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default ScenarioDetails;
