import React from 'react';

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';

import ChatBar from 'layouts/ChatBar/ChatBar';

const ModelStatisticsTab = () => {
    return (
        <MDBox p={3}> {/* p={3} adds padding */}
            <MDTypography variant="h3" >
                Model Statistics
            </MDTypography>
{/*             <ChatBar /> */}
            <br/>
            <Card>
             <MDBox height="100px" display="flex" justifyContent="center" alignItems="center">
             </MDBox>
          </Card>
        </MDBox>
    );
};

export default ModelStatisticsTab;