import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSignedUrl } from 'hooks/useSignedUrl';
import { useDropzone } from 'react-dropzone';

const EditBrandDialog = ({ open, onClose, brand, onSave }) => {
  const [originalBrandName, setOriginalBrandName] = useState(brand.brand_name);
  const [editedBrand, setEditedBrand] = useState({ ...brand });
  const [file_logo, setFileLogo] = useState(null);
  const [alert, setAlert] = useState({ show: false, message: '', color: '' });

  const signedUrl = useSignedUrl(brand.logo_url);

  useEffect(() => {
    setEditedBrand({ ...brand });
    setFileLogo(null); // Reset file_logo when the dialog is opened
  }, [brand]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedBrand({ ...editedBrand, [name]: value });
  };

  const handleSubmit = () => {
    onSave(originalBrandName, editedBrand, file_logo);
  };

  const onDropLogo = acceptedFiles => {
    const logoFile = acceptedFiles[0];
    if (logoFile) {
      setFileLogo(logoFile);
      const url = URL.createObjectURL(logoFile);
      setEditedBrand(prev => ({ ...prev, logo_url: url }));
    }
  };

  const { getRootProps: getLogoProps, getInputProps: getLogoInputProps } = useDropzone({
    onDrop: onDropLogo,
    multiple: false,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif']
    },
    onDropRejected: () => {
      setAlert({ show: true, message: 'Only image files are allowed.', color: 'warning' });
    }
  });

  const logo_preview = file_logo ? URL.createObjectURL(file_logo) : signedUrl;

  const sanitizeName = (name) => {
      return name.replace(/[^a-zA-Z0-9\s-]/g, '');
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Brand</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Brand Name"
          fullWidth
          variant="outlined"
          name="brand_name"
          value={sanitizeName(editedBrand.brand_name)}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Subname"
          fullWidth
          variant="outlined"
          name="sub_name"
          value={editedBrand.sub_name}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Industry"
          fullWidth
          variant="outlined"
          name="industry"
          value={editedBrand.industry}
          onChange={handleChange}
        />
        <div {...getLogoProps()} style={{ border: '2px dashed #ccc', borderRadius: '4px', padding: '10px', textAlign: 'center', cursor: 'pointer', marginTop: '10px' }}>
          <input {...getLogoInputProps()} />
          {logo_preview ? (
            <img src={logo_preview} alt="Logo Preview" style={{ height: '80px' }} />
          ) : (
            <p>Drop your logo here or click to upload</p>
          )}
          {file_logo && (
            <IconButton
              size="small"
              onClick={() => {
                setFileLogo(null);
                setEditedBrand(prev => ({ ...prev, logo_url: signedUrl }));
              }}
              sx={{ position: 'absolute', top: 0, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditBrandDialog;
