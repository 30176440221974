import React, { useEffect, useState, useMemo, useCallback } from 'react';
import axios from 'axios';
import { useAuthInfo } from "@propelauth/react";
import { ObjectInspector } from 'react-inspector';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import Card from "@mui/material/Card";
import pymcIcon from 'assets/images/pymc.png';
import robynIcon from 'assets/images/robyn.png';
import lightweightIcon from 'assets/images/lightweight.png';
import olsIcon from 'assets/images/ols.png';
import { MaterialReactTable } from 'material-react-table';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Modal } from "@mui/material";
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Menu, MenuItem } from "@mui/material";

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#e0e0e0',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          backgroundColor: '#F1F1F1',
          '&.MuiTableRow-head': {
            backgroundColor: '#F1F1F1',
          },
          '&:hover': {
            backgroundColor: '#cccccc', // Optional hover effect for the header row
          },
        },
        root: {
          '&.MuiTableRow-root': {
            backgroundColor: '#ffffff', // Default for regular rows
          },
          '&:hover': {
            backgroundColor: '#cccccc',
            cursor: 'pointer',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#333',
        },
      },
    },
  },
});

const downloadJson = (jsonContent, fileName) => {
  const jsonStr = JSON.stringify(jsonContent, null, 2);
  const blob = new Blob([jsonStr], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}.json`;
  a.click();
  URL.revokeObjectURL(url);
};

const DisplayJsonModal = ({ open, onClose, jsonContent, fileName }) => (
  <Modal open={open} onClose={onClose}>
    <div style={{ maxWidth: '80%', maxHeight: '80%', margin: '5% auto', backgroundColor: 'white', padding: '20px', borderRadius: '8px', overflowY: 'auto' }}>
      <MDTypography variant="h5" fontWeight="bold" gutterBottom>
        {fileName}
      </MDTypography>
      <div style={{ maxHeight: '60vh', overflowY: 'auto', fontSize: '12px' }}>
        <ObjectInspector data={jsonContent} />
      </div>
      <MDButton onClick={() => downloadJson(jsonContent, fileName)} style={{ marginTop: '10px', marginRight: '10px' }}>Download JSON</MDButton>
      <MDButton onClick={onClose} style={{ marginTop: '10px' }}>Close</MDButton>
    </div>
  </Modal>
);

const getModelIcon = (modelType) => {
  switch (modelType?.toLowerCase()) {
    case 'pymc':
      return pymcIcon;
    case 'robyn':
      return robynIcon;
    case 'lightweight':
      return lightweightIcon;
    case 'ols':
      return olsIcon;
    default:
      return null;
  }
};

const DisplayJsonContent = React.memo(({ modelType, data, error }) => {
  const transformedData = useMemo(() => {
    try {
      return typeof data === 'string' ? JSON.parse(data) : data;
    } catch (e) {
      console.error("Error parsing JSON data:", e);
      return [];
    }
  }, [data]);

    const columns = useMemo(() => [
      {
        accessorKey: 'Variable',
        header: 'Category', // Rename to Category
        Cell: ({ row }) => {
              const categoryValue = row.original['Category'] || row.original['Variable'] || row.original['Target Variable'];
              return categoryValue || ''; // Display Category value, fall back to Variable or Target Variable if needed
        },
      },
      {
        accessorKey: 'Ad Spend',
        header: 'Ad Spend',
        Cell: ({ cell }) => {
              const adSpendValue = parseFloat(cell.getValue());
              if (isNaN(adSpendValue)) return ''; // Handle non-numeric or missing data

              const formattedValue = Math.abs(adSpendValue).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
              return adSpendValue < 0 ? `-$${formattedValue}` : `$${formattedValue}`; // Negative sign before the dollar sign
        },
      },
      {
        accessorKey: 'Contribution',
        header: 'Contribution',
        Cell: ({ cell }) => {
              const contributionValue = parseFloat(cell.getValue());
              if (isNaN(contributionValue)) return ''; // Handle non-numeric or missing data

              const formattedValue = Math.abs(contributionValue).toLocaleString(); // No specific formatting, just localized
              return contributionValue < 0 ? `-$${formattedValue}` : `$${formattedValue}`; // Negative sign before the dollar sign
        },
      },
      {
        accessorKey: 'Marginal Contribution',
        header: 'Marginal Contribution',
        Cell: ({ cell }) => {
          const marginalContributionValue = parseFloat(cell.getValue());
          return isNaN(marginalContributionValue) ? '' : marginalContributionValue.toLocaleString(); // Avoid NaN for Marginal Contribution
        },
      },
      {
        accessorKey: 'ROI',
        header: 'ROI',
        Cell: ({ cell }) => {
          const roiValue = parseFloat(cell.getValue());
          return isNaN(roiValue) ? '' : roiValue.toFixed(2); // Format ROI with 2 decimals
        },
      },
      {
        accessorKey: 'MROI',
        header: 'MROI',
        Cell: ({ cell }) => {
          const mroiValue = parseFloat(cell.getValue());
          return isNaN(mroiValue) ? '' : mroiValue.toFixed(2); // Format MROI with 2 decimals
        },
      },
    ], [modelType]);

  if (!data) {
    return <p>{error || "No data available"}</p>;
  }

  return (
    <ThemeProvider theme={theme}>
      <MaterialReactTable
        columns={columns}
        data={transformedData}
        enableExpanding
        getSubRows={(row) => row.subRows || []}
      />
    </ThemeProvider>
  );
});

const ROISummaryTab = React.memo(({ refresh }) => {
  const [modelsOutputs, setModelsOutputs] = useState({});
  const [errors, setErrors] = useState({});
  const [jsonContent, setJsonContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const auth = useAuthInfo();

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (format, fileName) => {
    setAnchorEl(null);
    if (format) {
      downloadResults(format, modelsOutputs[fileName], fileName);
    }
  };

  const downloadResults = (format, content, fileName) => {
    const fileType = format === 'json' ? 'application/json' : 'application/octet-stream';
    const blob = new Blob([JSON.stringify(content, null, 2)], { type: fileType });
    saveAs(blob, `${fileName}.${format}`);
  };

  const fetchModelsOutputs = useCallback(async (modelsOutputs) => {
    const queryParams = new URLSearchParams({ models_outputs: JSON.stringify(modelsOutputs) }).toString();
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const fullUrl = `${baseUrl}/model/display_model_roi_summary?${queryParams}`;
    try {
      const response = await axios.post(
        fullUrl, {},
        {
          headers: {
            "Authorization": `Bearer ${auth.accessToken}`,
            "Accept": "application/json"
          }
        }
      );
      //console.log("ROI: " + JSON.stringify(response.data.data, null, 2))
      if (response.status === 200 && response.data.success) {
        setModelsOutputs(response.data.data);
      } else {
        const errorDetail = response.data.detail || 'Unknown error';
        setErrors(prevState => ({ ...prevState, general: errorDetail }));
      }
    } catch (error) {
      const errorDetail = error.response ? error.response.data.detail : error.message;
      setErrors(prevState => ({ ...prevState, general: errorDetail }));
    }
  }, [auth.accessToken]);

  const handleViewJsonClick = (modelType) => {
    setJsonContent(modelsOutputs[modelType]);
    setSelectedFileName(modelType);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (refresh && refresh['Models_results']) {
      const modelsOutputs = {};
      refresh['Models_results'].forEach(file => {
        modelsOutputs[file.model_type] = file.file_url;
      });
      fetchModelsOutputs(modelsOutputs);
    }
  }, [refresh, fetchModelsOutputs]);

  if (!refresh) return null;

  return (
    <MDBox p={3} pb={3} pr={5}>
      <MDTypography variant="h3">
        ROI Summary
      </MDTypography>
      <br />
      <Card>
        <MDBox p={3}>
          {Object.keys(modelsOutputs).map((modelType, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <MDTypography variant="h5" gutterBottom>
                <img
                  src={getModelIcon(modelType)}
                  alt={modelType}
                  style={{ width: '32px', height: '32px', marginLeft: '15px', marginRight: '10px', verticalAlign: 'middle' }}
                />
                {modelType.toUpperCase()}
                <IconButton onClick={(event) => {
                  setSelectedFileName(modelType);
                  handleDownloadClick(event);
                }}>
                  <DownloadIcon />
                </IconButton>
                <IconButton
                    onClick={() => handleViewJsonClick(modelType)}
                    sx={{ color: 'blue' }}>
                  <VisibilityIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => handleMenuClose(null)}
                >
                  <MenuItem onClick={() => handleMenuClose('json', selectedFileName)}>Download JSON</MenuItem>
                </Menu>
              </MDTypography>
              <DisplayJsonContent
                modelType={modelType}
                data={modelsOutputs[modelType]}
                error={errors[modelType]}
              />
            </div>
          ))}
        </MDBox>
      </Card>
      {jsonContent && (
        <DisplayJsonModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          jsonContent={jsonContent}
          fileName={selectedFileName}
        />
      )}
    </MDBox>
  );
});

export default ROISummaryTab;
