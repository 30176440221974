import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Alert, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useBrand } from '../../../../BrandContext';
import { useNavigate } from 'react-router-dom';
import { useAuthInfo } from "@propelauth/react";

function GetFileData({ sheetName, onGetData, file }) {
    const { selectedBrand, selectedRefresh } = useBrand();
    const [alert, setAlert] = useState({ show: false, message: '', color: '' });
    const [openDialog, setOpenDialog] = useState(false);
    const [navigateOnClose, setNavigateOnClose] = useState(false); // Flag to determine if we should navigate on close

    const auth = useAuthInfo();
    const navigate = useNavigate();

    const handleCloseDialog = () => {
        setOpenDialog(false);
        if (navigateOnClose) {
            navigate(`/brand-manager/${selectedBrand.brand_name}/${selectedRefresh.refresh_name}`);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const formData = new FormData();
            formData.append('file_url', file.file.file_url);
            formData.append('version', file.version);
            formData.append('sheet_name', sheetName);

            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const fullUrl = `${baseUrl}/brands/get_file_version_df`;
            try {
                const response = await axios.post(fullUrl, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${auth.accessToken}`
                    }
                });

                if (response.data) {
                    onGetData(sheetName, response.data);
                    //setAlert({ show: true, message: 'File data uploaded successfully!', color: 'success' });
                } else {
                    setAlert({ show: true, message: 'No data found in the file.', color: 'warning' });
                }
            } catch (error) {
                console.error('Error uploading file:', error);
                setAlert({ show: true, message: `Error uploading file data - Select a file version first in refresh ${selectedRefresh.refresh_name}`, color: 'error' });
                setNavigateOnClose(true);
            } finally {
                setOpenDialog(true);
            }
        };

        fetchData();
    }, [file, sheetName, auth.accessToken, onGetData]);

    return (
        <>
            {alert.show && (
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>File Upload Status</DialogTitle>
                    <DialogContent>
                        <Alert color={alert.color}>{alert.message}</Alert>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'flex-end' }}>
                        <Button onClick={handleCloseDialog} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

export default GetFileData;
