import React, { useContext, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useBrand } from '../../../../../BrandContext';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 205,
    },
  },
};

function getStyles(name, salesVariable, theme) {
  return {
    fontWeight:
      salesVariable.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SalesVariable({ variables = [] }) {
  const theme = useTheme();
  const { configurationDataUserSelection, updateConfigurationDataUserSelection } = useBrand();

  if (!Array.isArray(variables)) {
    variables = [];
  }
  const filterValidVariables = (vars) => Array.isArray(vars) ? vars.filter(variable => variables.includes(variable)) : [];

  const [salesVariable, setSalesVariable] = React.useState(() => {
    // Initialize configurationDataUserSelection.salesVariable with valid variables value if available
    if (configurationDataUserSelection?.salesVariable && Array.isArray(configurationDataUserSelection.salesVariable) && configurationDataUserSelection.salesVariable.length) {
      return filterValidVariables(configurationDataUserSelection.salesVariable);
    }
    return variables.length > 0 ? [variables[0]] : [];
  });

  useEffect(() => {
    const initialSalesVariable = configurationDataUserSelection?.salesVariable && Array.isArray(configurationDataUserSelection.salesVariable) && configurationDataUserSelection.salesVariable.length
      ? filterValidVariables(configurationDataUserSelection.salesVariable)
      : (variables.length > 0 ? [variables[0]] : []);
    setSalesVariable(initialSalesVariable);
    updateConfigurationDataUserSelection('salesVariable', initialSalesVariable);
  }, [variables, configurationDataUserSelection?.salesVariable, updateConfigurationDataUserSelection]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const newValue = typeof value === 'string' ? value.split(',') : value;
    const validValues = filterValidVariables(newValue);
    setSalesVariable(validValues);
    updateConfigurationDataUserSelection('salesVariable', validValues);
  };

  return (
    <FormControl sx={{ m: 1, width: 205 }}>
      <InputLabel id="variable-label">Variable</InputLabel>
      <Select
        sx={{ margin: '8px', padding: '5px 0' }}
        labelId="variable-label"
        id="multiple-variable"
        multiple
        value={salesVariable.length > 0 ? salesVariable : []}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-variable" label="Variable" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} sx={{ height: '10px', padding: '8px 1px' }} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
      {variables.length > 0 ? (
          variables.map((name) => (
            <MenuItem
              sx={{ height: '15px', padding: '10px', marginTop: '5px' }}
              key={name}
              value={name}
              style={getStyles(name, salesVariable, theme)}
            >
              {name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No options available</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
