import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import MDTypography from 'components/MDTypography';
import 'chartjs-adapter-moment';
import "assets/css/slider.css";
import MDBox from 'components/MDBox';
import { useBrand } from '../../../../BrandContext';
import {
    RangeNavigatorComponent,
    AreaSeries,
    DateTime,
    Inject,
    RangeTooltip,
    RangenavigatorSeriesDirective,
    RangenavigatorSeriesCollectionDirective,
    PeriodSelector
} from '@syncfusion/ej2-react-charts';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense(process.env.REACT_APP_SYNCFUSION_KEY);

function MarketingGraph({ data }) {
    const { configurationDataUserSelection } = useBrand();
    const channels = configurationDataUserSelection?.adsChannels || [];
    const metrics = configurationDataUserSelection?.adsSpendMetric || [];
    const adsVariable = configurationDataUserSelection?.adsVariable?.[0] || '';
    const date_column = configurationDataUserSelection?.date?.[0] || '';

    const [selectedMetric, setSelectedMetric] = useState(metrics[0] || '');
    const [selectedChannel, setSelectedChannel] = useState('All');
    const [selectedRange, setSelectedRange] = useState([new Date(), new Date()]);
    const [uniqueChannels, setUniqueChannels] = useState([]);
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });
    const chartRef = useRef();

    const safeData = useMemo(() => {
        console.log("Received Data:", data);
        return Array.isArray(data) ? data : [];
    }, [data]);

    const parseDate = (dateString) => {
        if (!dateString || typeof dateString !== 'string') return null;
        const parsedDate = new Date(dateString.split('T')[0]);
        return isNaN(parsedDate) ? null : parsedDate;
    };

    const generateChartData = useCallback((filteredData) => {
        if (!filteredData.length) {
            console.log("No data available to generate chart.");
            return { labels: [], datasets: [] };
        }

        const uniqueDates = [...new Set(filteredData.map(item => parseDate(item[date_column])))].sort((a, b) => a - b);
        const channelColors = uniqueChannels.reduce((acc, channel) => {
            acc[channel] = getRandomColor();
            return acc;
        }, {});

        const datasets = uniqueChannels.map(channel => {
            if (selectedChannel !== 'All' && selectedChannel !== channel) {
                return null;
            }
            const channelData = uniqueDates.map(date => {
                const item = filteredData.find(i =>
                    parseDate(i[date_column])?.getTime() === date?.getTime() &&
                    i[adsVariable] === channel
                );
                return { x: date, y: item && typeof item[selectedMetric] === 'number' ? item[selectedMetric] : 0 };
            }).filter(point => point.y !== null && point.y !== undefined && !isNaN(point.y));

            return {
                label: channel,
                data: channelData,
                fill: false,
                borderColor: channelColors[channel],
                backgroundColor: channelColors[channel],
            };
        }).filter(dataset => dataset);

        console.log("Generated Chart Data:", { labels: uniqueDates, datasets: datasets });
        return {
            labels: uniqueDates,
            datasets: datasets
        };
    }, [selectedChannel, selectedMetric, uniqueChannels, adsVariable, date_column]);

    useEffect(() => {
        const filteredData = selectedRange[0] && selectedRange[1]
            ? safeData.filter(item => {
                const itemDate = parseDate(item[date_column]);
                return itemDate && itemDate >= selectedRange[0] && itemDate <= selectedRange[1];
            })
            : safeData;

        const chartData = generateChartData(filteredData);
        setChartData(chartData);
        console.log("Final Chart Data to Render:", chartData);
    }, [safeData, selectedChannel, selectedMetric, selectedRange, generateChartData, date_column]);

    useEffect(() => {
        if (safeData.length === 0) return;
        setUniqueChannels([...new Set(safeData.map(item => item[adsVariable]))].filter(channel => channels.includes(channel)));
    }, [safeData, channels, adsVariable]);

    const handleRangeChange = (args) => {
        const newStartDate = parseDate(args.start);
        const newEndDate = parseDate(args.end);

        if (newStartDate && newEndDate && newStartDate instanceof Date && newEndDate instanceof Date) {
            if (!selectedRange[0] || !selectedRange[1] ||
                selectedRange[0].getTime() !== newStartDate.getTime() ||
                selectedRange[1].getTime() !== newEndDate.getTime()) {

                setSelectedRange([newStartDate, newEndDate]);

                const filteredData = safeData.filter(item => {
                    const itemDate = parseDate(item[date_column]);
                    return itemDate && itemDate >= newStartDate && itemDate <= newEndDate;
                });
                setChartData(generateChartData(filteredData));
            }
        } else {
            console.error("Invalid date range selected:", args.start, args.end);
        }
    };

    if (safeData.length === 0) {
        return <MDTypography variant="body2">No Marketing data available.</MDTypography>;
    }

    const periodselector = {
        position: 'Top',
        periods: [
            { text: '1M', interval: 1, intervalType: 'Months' },
            { text: '3M', interval: 3, intervalType: 'Months' },
            { text: '6M', interval: 6, intervalType: 'Months' },
            { text: 'YTD' },
            { text: '1Y', interval: 1, intervalType: 'Years' },
            { text: '2Y', interval: 2, intervalType: 'Years', selected: true },
            { text: 'All' }
        ]
    };

    const getTransformedData = () => {
        return safeData
            .filter(item => (selectedChannel === 'All' || item[adsVariable] === selectedChannel) && channels.includes(item[adsVariable]))
            .map(item => ({ x: parseDate(item[date_column]), y: item && typeof item[selectedMetric] === 'number' ? item[selectedMetric] : 0 }))
            .filter(point => point.y !== null && point.y !== undefined && !isNaN(point.y));
    };

    const lineOptions = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'MMM DD, YY'
                    },
                    tooltipFormat: 'll'
                },
                title: {
                    display: true,
                    text: 'Date'
                }
            },
            y: {
                title: {
                    display: true,
                    text: selectedMetric
                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Marketing Data'
            }
        }
    };

    return (
        <MDBox>
            <MDTypography variant="h5">{`${selectedMetric} Over Time by ${adsVariable}`}</MDTypography>
            <select className="style-select select-spacing" onChange={(e) => setSelectedMetric(e.target.value)} value={selectedMetric || (metrics.length > 0 ? metrics[0] : '')}>
                {metrics.map((metric, index) => (
                    <option key={index} value={metric}>{metric}</option>
                ))}
            </select>
            <select className="style-select select-spacing" onChange={(e) => setSelectedChannel(e.target.value)} value={selectedChannel}>
                <option value="All">All Channels</option>
                {uniqueChannels.map(channel => (
                    <option key={channel} value={channel}>{channel}</option>
                ))}
            </select>
            <Line height="110px" ref={chartRef} data={chartData} options={lineOptions} />
            <br />
            <RangeNavigatorComponent
                id='rangenavigator'
                valueType='DateTime'
                labelFormat='MMM-yy'
                value={selectedRange}
                tooltip={{ enable: true, format: 'yyyy/MM/dd', displayMode: 'Always' }}
                periodSelectorSettings={periodselector}
                changed={handleRangeChange}
                allowSnapping={true}
            >
                <Inject services={[AreaSeries, DateTime, RangeTooltip, PeriodSelector]} />
                <RangenavigatorSeriesCollectionDirective>
                    <RangenavigatorSeriesDirective
                        dataSource={getTransformedData()}
                        xName='x'
                        yName='y'
                        type='Area'
                        width={2}
                    />
                </RangenavigatorSeriesCollectionDirective>
            </RangeNavigatorComponent>
        </MDBox>
    );
}

function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export default MarketingGraph;
