import React, { useState, useEffect, useRef, useCallback, useMemo, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import "assets/css/slider.css";
import { useBrand } from '../../../../BrandContext';
import {
    RangeNavigatorComponent,
    AreaSeries,
    DateTime,
    Inject,
    RangeTooltip,
    RangenavigatorSeriesDirective,
    RangenavigatorSeriesCollectionDirective,
    PeriodSelector
} from '@syncfusion/ej2-react-charts';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense(process.env.REACT_APP_SYNCFUSION_KEY);

function SalesGraph({ data }) {
    const { configurationDataUserSelection } = useBrand();
    const channels = configurationDataUserSelection?.salesChannels || [];
    const metrics = configurationDataUserSelection?.salesMetric || [];
    const date_column = configurationDataUserSelection?.date && configurationDataUserSelection.date[0] ? configurationDataUserSelection.date[0] : '';

    const [selectedMetric, setSelectedMetric] = useState(metrics[0] || '');
    const [selectedChannel, setSelectedChannel] = useState('All');
    const [uniqueChannels, setUniqueChannels] = useState([]);
    const [selectedRange, setSelectedRange] = useState({ start: null, end: null });
    const chartRef = useRef();

    const safeData = useMemo(() => Array.isArray(data) ? data : [], [data]);

    const parseDate = (dateString) => {
        if (!dateString || typeof dateString !== 'string') return null;
        const parsedDate = new Date(dateString.split('T')[0]);
        return isNaN(parsedDate) ? null : parsedDate;
    };

    const generateChartData = useCallback((filteredData) => {
        if (!filteredData.length) {
            return { labels: [], datasets: [] };
        }

        const uniqueDates = [...new Set(filteredData.map(item => parseDate(item[date_column])))].sort((a, b) => a - b);
        const uniqueChannels = [...new Set(filteredData.map(item => item["Sales Channel"]))];
        const channelColors = uniqueChannels.reduce((acc, channel) => {
            acc[channel] = getRandomColor();
            return acc;
        }, {});

        const datasets = uniqueChannels.map(channel => {
            if (selectedChannel !== 'All' && selectedChannel !== channel) {
                return null;
            }
            if (!channels.includes(channel)) {
                return null;
            }
            const channelData = uniqueDates.map(date => {
                const item = filteredData.find(i => parseDate(i[date_column])?.getTime() === date?.getTime() && i["Sales Channel"] === channel);
                return { x: date, y: item ? item[selectedMetric] : 0 };
            });
            return {
                label: channel,
                data: channelData,
                fill: false,
                borderColor: channelColors[channel],
                backgroundColor: channelColors[channel],
            };
        }).filter(dataset => dataset);

        return {
            labels: uniqueDates,
            datasets: datasets
        };
    }, [selectedMetric, selectedChannel, channels, date_column]);

    const [chartData, setChartData] = useState(generateChartData(safeData));

    useEffect(() => {
        if (safeData.length === 0) return;
        const filteredData = selectedRange.start && selectedRange.end
            ? safeData.filter(item => {
                const itemDate = parseDate(item[date_column]);
                return itemDate >= selectedRange.start && itemDate <= selectedRange.end;
            })
            : safeData;

        setChartData(generateChartData(filteredData));
    }, [safeData, selectedChannel, selectedMetric, selectedRange, generateChartData, date_column]);

    useEffect(() => {
        if (safeData.length === 0) return;
        setUniqueChannels([...new Set(safeData.map(item => item["Sales Channel"]))].filter(channel => channels.includes(channel)));
    }, [safeData, channels]);

    if (safeData.length === 0) {
        return <MDTypography variant="body2">No Sales data available.</MDTypography>;
    }

    const periodselector = {
        position: 'Top',
        periods: [
            { text: '1M', interval: 1, intervalType: 'Months' },
            { text: '3M', interval: 3, intervalType: 'Months' },
            { text: '6M', interval: 6, intervalType: 'Months' }, { text: 'YTD' },
            { text: '1Y', interval: 1, intervalType: 'Years' },
            { text: '2Y', interval: 2, intervalType: 'Years', selected: true }, { text: 'All' }
        ]
    };

    const handleRangeChange = (args) => {
        const newStartDate = new Date(args.start);
        const newEndDate = new Date(args.end);

        if (!selectedRange.start || !selectedRange.end ||
            selectedRange.start.getTime() !== newStartDate.getTime() ||
            selectedRange.end.getTime() !== newEndDate.getTime()) {

            setSelectedRange({ start: newStartDate, end: newEndDate });

            const filteredData = safeData.filter(item => {
                const itemDate = parseDate(item[date_column]);
                return itemDate >= newStartDate && itemDate <= newEndDate;
            });
            setChartData(generateChartData(filteredData));
        }
    };

    const getTransformedData = () => {
        return safeData
            .filter(item => selectedChannel === 'All' || item["Sales Channel"] === selectedChannel)
            .map(item => ({ x: parseDate(item[date_column]), y: item[selectedMetric] }));
    };

    const lineOptions = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'MMM DD, YY'
                    },
                    tooltipFormat: 'll'
                },
                title: {
                    display: true,
                    text: 'Date'
                }
            },
            y: {
                title: {
                    display: true,
                    text: selectedMetric
                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Sales Data'
            }
        }
    };

    return (
        <MDBox>
            <select className="style-select select-spacing" onChange={(e) => setSelectedMetric(e.target.value)} value={selectedMetric}>
                {metrics.map((metric, index) => (
                    <option key={index} value={metric}>{metric}</option>
                ))}
            </select>
            <select className="style-select select-spacing" onChange={(e) => setSelectedChannel(e.target.value)} value={selectedChannel}>
                <option value="All">All Channels</option>
                {uniqueChannels.map(channel => (
                    <option key={channel} value={channel}>{channel}</option>
                ))}
            </select>
            <Line height="110px" id='chart1' ref={chartRef} data={chartData} options={lineOptions} />
            <br />
            <RangeNavigatorComponent
                id='rangenavigator'
                valueType='DateTime'
                labelFormat='MMM dd, yy'
                value={[selectedRange.start, selectedRange.end]}
                tooltip={{ enable: true, format: 'MMM dd, yy', displayMode: 'Always' }}
                periodSelectorSettings={periodselector}
                changed={handleRangeChange}>
                <Inject services={[AreaSeries, DateTime, RangeTooltip, PeriodSelector]} />
                <RangenavigatorSeriesCollectionDirective>
                    <RangenavigatorSeriesDirective
                        dataSource={getTransformedData()}
                        xName='x'
                        yName='y'
                        type='Area'
                        width={2}
                    />
                </RangenavigatorSeriesCollectionDirective>
            </RangeNavigatorComponent>
        </MDBox>
    );
}

function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export default SalesGraph;
