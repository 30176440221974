import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Grid, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, IconButton, Menu, MenuItem, ListItemIcon, Alert, DialogContentText, CircularProgress, FormControl, InputLabel, Select } from '@mui/material';
import { blue } from '@mui/material/colors';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from 'components/MDTypography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import DataTable from "assets/Tables/DataTable";
import ScenarioDetails from './ScenarioDetails';
import { v4 as uuidv4 } from 'uuid';
import { useBrand } from '../../../BrandContext';
import { useAuthInfo } from "@propelauth/react";
import { useNavigate } from 'react-router-dom';

import pymcIcon from 'assets/images/pymc.png';
import robynIcon from 'assets/images/robyn.png';
import lightweightIcon from 'assets/images/lightweight.png';
import olsIcon from 'assets/images/ols.png';

function ScenarioList() {
    const [scenarios, setScenarios] = useState([]);
    const [refreshes, setRefreshes] = useState([]);
    const [modelTypes, setModelTypes] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentScenario, setCurrentScenario] = useState(null);
    const [selectedScenario, setSelectedScenario] = useState(null);
    const [errors, setErrors] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const { selectedBrand, selectedRefresh } = useBrand();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', color: '' });
    const [openDialog, setOpenDialog] = useState(false);
    const [open, setOpen] = useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [scenarioToDelete, setScenarioToDelete] = useState(null);
    const auth = useAuthInfo();
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
    };

    const getModelIcon = (modelType) => {
        switch (modelType?.toLowerCase()) {
            case 'pymc':
                return pymcIcon;
            case 'robyn':
                return robynIcon;
            case 'lightweight':
                return lightweightIcon;
            case 'ols':
                return olsIcon;
            default:
                return null;
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    useEffect(() => {
        if (selectedBrand) {
            fetchBrandRefreshes();
            fetchScenarios();
        }
    }, [selectedBrand]);

    const fetchBrandRefreshes = async () => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/brands/get_refreshes?brand_name=${encodeURIComponent(selectedBrand.brand_name)}`;
        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`
                },
            });
            if (response.data && Array.isArray(response.data.refreshes)) {
                setRefreshes(response.data.refreshes);
            } else {
                setRefreshes([]);
            }
        } catch (error) {
            console.error('Error getting all refreshes:', error);
            setAlert({ show: true, message: `${error.response.data.detail}`, color: 'error' });
            setOpenDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const fetchScenarios = async () => {
        setLoading(true);
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/scenarios/get_scenarios?brand_name=${encodeURIComponent(selectedBrand.brand_name)}`;
        try {
            const response = await axios.get(fullUrl, {
                headers: {
                    "Authorization": `Bearer ${auth.accessToken}`
                },
            });
            if (response.data) {
                if (response.data.message) {
                    setScenarios([]);
                } else {
                    setScenarios(response.data);
                }
            }
        } catch (error) {
            console.error('Error fetching scenarios:', error);
            setAlert({ show: true, message: 'Failed to load scenarios', color: 'error' });
            setOpenDialog(true);
        } finally {
            setLoading(false);
        }
    };

    const validateFields = () => {
        const newErrors = {};
        if (!currentScenario.scenario) newErrors.scenario = 'A scenario name is required';
        if (!currentScenario.scenarioGoal) newErrors.scenarioGoal = 'A scenario goal selection is required';
        if (!currentScenario.optimizationGoal) newErrors.optimizationGoal = 'An optimization goal selection is required';
        if (!currentScenario.refresh) newErrors.refresh = 'A refresh selection is required';
        if (!currentScenario.modelType) newErrors.modelType = 'A model type selection is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleDialogOpen = () => {
        setCurrentScenario({
            scenario: '',
            description: '',
            scenarioGoal: '',
            optimizationGoal: '',
            roi: '',
            refresh: '',
            modelType: '',
            dateCreated: new Date().toLocaleString(),
            details: '',
        });
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setCurrentScenario(null);
        setIsEditMode(false);
        setErrors({});
    };

    function getModelTypesByRefreshName(data, targetRefreshName) {
        const matchedRefresh = data.find(item => item.refresh_name === targetRefreshName);
        if (matchedRefresh && matchedRefresh.Models_results && matchedRefresh.Models_results.length > 0) {
            return matchedRefresh.Models_results.map(result => result.model_type);
        } else {
            return [];
        }
    }

    const handleRowClick = (scenario) => {
        navigate(`/scenarios/${scenario.scenario}`, {
            state: {
                scenario,
                selectedTab: 2,
            },
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentScenario({
            ...currentScenario,
            [name]: value,
        });

        if (name === 'refresh') {
            const types = getModelTypesByRefreshName(refreshes, value);
            setModelTypes(types);

            if (types.length > 0) {
                setCurrentScenario((prevScenario) => ({
                    ...prevScenario,
                    modelType: types[0],
                }));
            } else {
                setCurrentScenario((prevScenario) => ({
                    ...prevScenario,
                    modelType: '',
                }));
            }
        }
    };

    const handleCreateOrEdit = async () => {
        if (validateFields()) {
            if (isEditMode && currentScenario) {
                await updateScenario(currentScenario);
            } else {
                await createScenario(currentScenario);
            }
            setIsDialogOpen(false);
            setCurrentScenario(null);
            setIsEditMode(false);
            setErrors({});
        }
    };

    const createScenario = async (scenarioData) => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/scenarios/create_scenario?brand_name=${encodeURIComponent(selectedBrand.brand_name)}`;
        try {
            const response = await axios.post(fullUrl, {
                id: uuidv4(),
                scenario: scenarioData.scenario,
                description: scenarioData.description,
                scenarioGoal: scenarioData.scenarioGoal,
                optimizationGoal: scenarioData.optimizationGoal,
                details: scenarioData.details,
                refresh: scenarioData.refresh,
                roi: scenarioData.roi,
                modelType: scenarioData.modelType,
                dateCreated: new Date().toLocaleString(),
            }, {
                headers: { "Authorization": `Bearer ${auth.accessToken}` },
            });
            scenarioData.id = response.data.scenario_id;
            setScenarios([...scenarios, scenarioData]);
        } catch (error) {
            console.error('Error creating scenario:', error);
        }
    };

    const updateScenario = async (scenarioData) => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/scenarios/update_scenario?brand_name=${encodeURIComponent(selectedBrand.brand_name)}`;
        try {
            const response = await axios.post(fullUrl, {
                id: scenarioData.id,
                scenario: scenarioData.scenario,
                description: scenarioData.description,
                scenarioGoal: scenarioData.scenarioGoal,
                optimizationGoal: scenarioData.optimizationGoal,
                details: scenarioData.details,
                refresh: scenarioData.refresh,
                roi: scenarioData.roi,
                modelType: scenarioData.modelType,
                dateCreated: scenarioData.dateCreated,
            }, {
                headers: { "Authorization": `Bearer ${auth.accessToken}` },
            });
            const updatedScenarios = scenarios.map((scenario) =>
                scenario.id === scenarioData.id ? scenarioData : scenario
            );
            setScenarios(updatedScenarios);
        } catch (error) {
            console.error('Error updating scenario:', error);
        }
    };

    const handleEdit = (scenario) => {
        setCurrentScenario(scenario);
        const refreshName = scenario.refresh;
        const types = getModelTypesByRefreshName(refreshes, refreshName);
        setModelTypes(types);

        setIsEditMode(true);
        setIsDialogOpen(true);
        setAnchorEl(null);
    };

    const handleDeleteConfirm = (scenario) => {
        setScenarioToDelete(scenario);
        setIsConfirmDialogOpen(true);
    };

    const handleDelete = async () => {
        const formData = new FormData();
        formData.append('brand_name', selectedBrand.brand_name);
        formData.append('id', scenarioToDelete.id);
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/scenarios/delete_scenario`;
        try {
            const response = await axios.post(fullUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${auth.accessToken}`
                },
            });
            const updatedScenarios = scenarios.filter((scenario) => scenario.id !== scenarioToDelete.id);
            setScenarios(updatedScenarios);
            setIsConfirmDialogOpen(false);
            setScenarioToDelete(null);
        } catch (error) {
            console.error('Error deleting scenario:', error);
        }
        setAnchorEl(null);
    };

    const handleDeleteCancel = () => {
        setIsConfirmDialogOpen(false);
        setScenarioToDelete(null);
    };

    const handleCopy = async (scenario) => {
        const copiedScenario = {
            ...scenario,
            id: uuidv4(),
            scenario: `${scenario.scenario} (Copy)`,
            dateCreated: new Date().toLocaleString(),
        };
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const fullUrl = `${baseUrl}/scenarios/create_scenario?brand_name=${encodeURIComponent(selectedBrand.brand_name)}`;
        try {
            const response = await axios.post(fullUrl, copiedScenario, {
                headers: { "Authorization": `Bearer ${auth.accessToken}` },
            });
            copiedScenario.id = response.data.scenario_id;
            setScenarios([...scenarios, copiedScenario]);
        } catch (error) {
            console.error('Error copying scenario:', error);
        }
        setAnchorEl(null);
    };

    const handleMenuClick = (event, scenario) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCurrentScenario(scenario);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleView = (scenario) => {
        setAnchorEl(null);
        navigate(`/scenarios/${scenario.scenario}`, { state: { scenario } });
    };

    const handleCloseScenarioDetails = () => {
        setSelectedScenario(null);
    };

    const columns = [
        {
            Header: <MDTypography fontWeight="bold" variant="h6">Scenario</MDTypography>,
            accessor: "scenario",
            minWidth: 100,
        },
        {
            Header: <MDTypography fontWeight="bold" variant="h6">Description</MDTypography>,
            accessor: "description",
            minWidth: 100,
        },
        {
            Header: <MDTypography fontWeight="bold" variant="h6">Scenario Goal</MDTypography>,
            accessor: "scenarioGoal",
            minWidth: 100,
        },
        {
            Header: <MDTypography fontWeight="bold" variant="h6">Optimization Goal</MDTypography>,
            accessor: "optimizationGoal",
            minWidth: 100,
        },
        {
            Header: <MDTypography fontWeight="bold" variant="h6">ROI</MDTypography>,
            accessor: "roi",
            minWidth: 100,
        },
        {
            Header: <MDTypography fontWeight="bold" variant="h6">Date Created</MDTypography>,
            accessor: "dateCreated",
            minWidth: 100,
        },
        {
            Header: <MDTypography fontWeight="bold" variant="h6">Refresh</MDTypography>,
            accessor: "refresh",
            minWidth: 100,
        },
        {
            Header: <MDTypography fontWeight="bold" variant="h6">Model Type</MDTypography>,
            accessor: "modelType",
            minWidth: 100,
            Cell: ({ value }) => {
                const modelIcon = getModelIcon(value);
                return modelIcon ? <img src={modelIcon} alt={value} style={{ width: '32px', height: '32px' }} /> : value;
            }
        },
        {
            Header: <MDTypography fontWeight="bold" variant="h6">Actions</MDTypography>,
            accessor: "actions",
            minWidth: 100,
        },
    ];

    const rows = scenarios.map((scenario) => ({
        ...scenario,
        actions: (
            <>
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        handleMenuClick(e, scenario);
                    }}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && currentScenario?.id === scenario.id}
                    onClose={handleMenuClose}
                >
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation();
                            handleView(scenario);
                        }}
                    >
                        <ListItemIcon>
                            <VisibilityIcon fontSize="small" sx={{ color: 'blue' }} />
                        </ListItemIcon>
                        View
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(scenario);
                        }}
                    >
                        <ListItemIcon>
                            <EditIcon fontSize="small" sx={{ color: '#006400' }} />
                        </ListItemIcon>
                        Edit
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteConfirm(scenario);
                        }}
                    >
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" sx={{ color: '#C31328' }} />
                        </ListItemIcon>
                        Delete
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCopy(scenario);
                        }}
                    >
                        <ListItemIcon>
                            <ContentCopyIcon fontSize="small" sx={{ color: blue[300] }} />
                        </ListItemIcon>
                        Copy
                    </MenuItem>
                </Menu>
            </>
        ),
        rowProps: {
            onClick: () => handleRowClick(scenario),
            style: { cursor: 'pointer' },
        },
    }));

    const textFieldStyles = {
        margin: 'dense',
        variant: 'outlined',
        fullWidth: true,
        sx: {
            '& .MuiInputBase-root': {
                height: '44px',
            },
        },
    };

    return (
        <>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{alert.color === 'success' ? 'Success' : 'Error'}</DialogTitle>
                <DialogContent>
                    {alert.show && <Alert color={alert.color}>{alert.message}</Alert>}
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end' }}>
                    <Button onClick={handleCloseDialog} color="primary" sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)', } }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isConfirmDialogOpen} onClose={handleDeleteCancel}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this Scenario with all its associated metadata?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="primary" sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)', } }}>
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary" sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)', } }}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <MDBox p={3} display="flex" flexDirection="column">
                <MDBox display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
                    <MDButton
                        variant="contained"
                        color="dark"
                        onClick={handleDialogOpen}
                        disabled={!selectedBrand || !selectedRefresh}
                        startIcon={<AddIcon />}
                    >
                        Create Scenario
                    </MDButton>
                </MDBox>

                {loading ? (
                    <MDBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress sx={{ color: blue[500] }} />
                        <MDTypography variant="body2" sx={{ mt: 2 }}>Loading scenarios for {selectedBrand.brand_name}...</MDTypography>
                    </MDBox>
                ) : (
                    <Card>
                        <MDBox p={3}>
                            <div className="data-table-wrapper">
                                <DataTable
                                    table={{ columns, rows }}
                                    rowProps={(row) => row.rowProps}
                                    isSorted={true}
                                    entriesPerPage={{
                                        defaultValue: 5,
                                        entries: [5, 10, 15, 20, 25]
                                    }}
                                    showTotalEntries={true}
                                    noEndBorder
                                    canSearch
                                />
                            </div>
                        </MDBox>
                    </Card>
                )}
                {selectedScenario && (
                    <Dialog
                        open={!!selectedScenario}
                        onClose={handleCloseScenarioDetails}
                        maxWidth="lg"
                        fullWidth
                    >
                        <DialogTitle>Scenario Details</DialogTitle>
                        <DialogContent>
                            <ScenarioDetails
                                scenario={selectedScenario}
                                handleClose={handleCloseScenarioDetails}
                                refreshes={refreshes}
                            />
                        </DialogContent>
                    </Dialog>
                )}

                <Dialog
                    open={isDialogOpen}
                    onClose={handleDialogClose}
                    disableEscapeKeyDown
                >
                    <DialogTitle>{isEditMode ? 'Edit Scenario' : 'Create Scenario'}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Scenario Name"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    name="scenario"
                                    value={currentScenario?.scenario || ''}
                                    onChange={handleChange}
                                    error={!!errors.scenario}
                                    helperText={errors.scenario}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="Description"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    name="description"
                                    value={currentScenario?.description || ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    error={!!errors.scenarioGoal}
                                    required
                                    {...textFieldStyles}
                                >
                                    <InputLabel>Scenario Goal</InputLabel>
                                    <Select
                                        label="Scenario Goal"
                                        name="scenarioGoal"
                                        value={currentScenario?.scenarioGoal || ''}
                                        onChange={handleChange}
                                        IconComponent={ArrowDropDownIcon}
                                        sx={{
                                            '& .MuiSelect-icon': {
                                                display: 'block',
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                            },
                                        }}
                                    >
                                        <MenuItem value="Optimize a Fixed Budget">Optimize a Fixed Budget</MenuItem>
                                        <MenuItem value="Recommend a New Budget">Recommend a New Budget</MenuItem>
                                        <MenuItem value="Meet Revenue Goal">Meet Revenue Goal</MenuItem>
                                    </Select>
                                    {errors.scenarioGoal && <p style={{ color: 'red' }}>{errors.scenarioGoal}</p>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                             <FormControl
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    error={!!errors.optimizationGoal}
                                    required
                                    {...textFieldStyles}
                                >
                                    <InputLabel>Optimization Goal</InputLabel>
                                    <Select
                                        label="Optimization Goal"
                                        name="optimizationGoal"
                                        value={currentScenario?.optimizationGoal || ''}
                                        onChange={handleChange}
                                        IconComponent={ArrowDropDownIcon}
                                        sx={{
                                            '& .MuiSelect-icon': {
                                                display: 'block',
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                            },
                                        }}
                                    >
                                        <MenuItem value="Revenue">Revenue</MenuItem>
                                        <MenuItem value="ROI">ROI</MenuItem>
                                        <MenuItem value="Profits">Profits</MenuItem>
                                    </Select>
                                    {errors.optimizationGoal && <p style={{ color: 'red' }}>{errors.optimizationGoal}</p>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                             <FormControl
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    error={!!errors.refresh}
                                    required
                                    {...textFieldStyles}
                                >
                                    <InputLabel>Refresh</InputLabel>
                                    <Select
                                        label="Refresh"
                                        name="refresh"
                                        value={currentScenario?.refresh || ''}
                                        onChange={handleChange}
                                        IconComponent={ArrowDropDownIcon}
                                        sx={{
                                            '& .MuiSelect-icon': {
                                                display: 'block',
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                            },
                                        }}
                                    >
                                          {refreshes.map((refresh) => (
                                               <MenuItem key={refresh.refresh_name} value={refresh.refresh_name}>
                                                   {refresh.refresh_name}
                                               </MenuItem>
                                           ))}
                                    </Select>
                                    {errors.refresh && <p style={{ color: 'red' }}>{errors.refresh}</p>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                             <FormControl
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    error={!!errors.modelType}
                                    required
                                    {...textFieldStyles}
                                >
                                    <InputLabel>Model Type</InputLabel>
                                    <Select
                                        label="Model Type"
                                        name="modelType"
                                        value={currentScenario?.modelType || ''}
                                        onChange={handleChange}
                                        IconComponent={ArrowDropDownIcon}
                                        sx={{
                                            '& .MuiSelect-icon': {
                                                display: 'block',
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                            },
                                        }}
                                    >
                                    {modelTypes.length > 0 ? (
                                        modelTypes.map((modelType, index) => (
                                            <MenuItem key={modelType} value={modelType}>
                                                {modelType}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="" disabled>
                                            No Model Types Available
                                        </MenuItem>
                                    )}
                                    </Select>
                                    {errors.modelType && <p style={{ color: 'red' }}>{errors.modelType}</p>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    label="Details"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    name="details"
                                    value={currentScenario?.details || ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose}
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button onClick={handleCreateOrEdit}
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                }
                            }}
                        >
                            {isEditMode ? 'Save' : 'Create'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </MDBox>
        </>
    );
}

export default ScenarioList;
