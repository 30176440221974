import BrandManager from "layouts/brand-manager/BrandManager";
import DataValidation from "layouts/data-validation/DataValidation";
import Modeling from "layouts/modeling/Modeling";
import AIInsights from "layouts/ai-insights/AIInsights";
import ModelDetails from "layouts/model-details/ModelDetails";
import ScenarioPlanner from "layouts/scenario-planner/ScenarioPlanner";
import LogOut from "layouts/authentication/logout";
// @mui icons
import Icon from "@mui/material/Icon";

import getAccountUrl from "./Config";

const accountUrl = getAccountUrl();

const routes_member = [
  {
    key: "brand-manager",
    type: "collapse",
    name: "Brand Manager",
    icon: <Icon fontSize="small">dashboard_customize</Icon>,
    route: "/brand-manager",
    component: <BrandManager />,
  },
  {
    key: "data-validation",
    type: "collapse",
    name: "Data Validation",
    icon: <Icon fontSize="small">fact_check</Icon>,
    route: "/data-validation",
    component: <DataValidation />,
  },
  {
    key: "modeling",
    type: "collapse",
    name: "Modeling",
    icon: <Icon fontSize="small">dataset</Icon>,
    route: "/modeling",
    component: <Modeling />,
  },
  {
    key: "ai-insights",
    type: "collapse",
    name: "AI Insights",
    icon: <Icon fontSize="small">insights</Icon>,
    route: "/ai-insights",
    component: <AIInsights />,
  },
  {
     key: "scenario-planner",
    type: "collapse",
    name: "Scenario Planner",
    icon: <Icon fontSize="small">business_center</Icon>,
    route: "/scenario-planner",
    component: <ScenarioPlanner />,
  },
  {
    key: "model-details",
    type: "collapse",
    name: "Model Details",
    icon: <Icon fontSize="small">details</Icon>,
    route: "/model-details",
    component: <ModelDetails />,
  },
  {
    key: "account",
    type: "collapse",
    name: "Account",
    icon: <Icon fontSize="small">person</Icon>,
    route: accountUrl+'/account',
  },
  {
    key: "logout",
    type: "collapse",
    name: "Log Out",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/logout",
    component: <LogOut />,
  }
];

export default routes_member;
